<template>
  <div class="content">
    <a-form-model v-show="topic == 1" ref="form" :model="form">
      <a-form-model-item label="基础设置">
        <a-row type="flex" justify="space-around">
          <a-col :span="11">
            <a-select
              v-model="form.wordCount"
              placeholder="请选择篇幅"
              option-filter-prop="children"
              show-search
              allowClear
            >
              <a-select-option v-for="(value, index) in WORD_COUNT_LIST" :key="index" :value="value.value">
                {{ value.text }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="11">
            <a-select
              v-model="form.storyType"
              option-filter-prop="children"
              show-search
              allowClear
              placeholder="故事视角"
            >
              <a-select-option v-for="item in STORY_TYPE_MAP" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <div class="top">
        <a-button type="link" v-if="form.storyWay === 'MODEL'" @click="handleModeChange('FREEDOM')"
          >切换: 模版</a-button
        >
        <a-button type="link" v-else-if="form.storyWay === 'FREEDOM'" @click="handleModeChange('MODEL')"
          >切换: 自由</a-button
        >
      </div>
      <a-form-model-item v-if="form.storyWay === 'FREEDOM'">
        <a-textarea :auto-size="{ minRows: 10, maxRows: 10 }" v-model="form.freeSetting"></a-textarea>
        <a-row type="flex" justify="end">
          <a-col style="color: #989898"
            >{{ form.freeSetting ? form.freeSetting.length : 0 }}个字
            <span style="cursor: pointer" @click="form.freeSetting = ''">清空</span></a-col
          >
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="人设" class="customer-style" v-if="form.storyWay === 'MODEL'">
        <a-row type="flex" justify="space-around">
          <a-col :span="23">
            <a-select
              v-model="form.customer"
              option-filter-prop="children"
              show-search
              allowClear
              placeholder="客户类型"
              @change="handleCustomerChange"
            >
              <a-select-option v-for="(item, index) in customerList" :key="index" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-around">
          <a-col :span="23">
            <a-select
              v-model="form.occupation"
              option-filter-prop="children"
              show-search
              allowClear
              placeholder="职业"
              @change="handleOccupationChange"
            >
              <a-select-option v-for="(item, index) in occupationList" :key="index" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-around">
          <a-col :span="23">
            <a-select
              v-model="form.lifestyle"
              option-filter-prop="children"
              show-search
              allowClear
              placeholder="生活方式"
              @change="handleLifestyleChange"
            >
              <a-select-option
                class="customer-dropdown-options"
                v-for="(item, index) in lifestyleList"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="23">
            <a-select
              v-model="form.setting"
              option-filter-prop="children"
              show-search
              allowClear
              placeholder="汽车使用场景"
              @change="handleSettingChange"
            >
              <a-select-option
                class="customer-dropdown-options"
                v-for="(item, index) in settingList"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="卖点" v-if="form.storyWay === 'MODEL'">
        <a-row type="flex" justify="center" align="top">
          <a-col :span="20">
            <a-select
              v-model="form.features"
              :filterOption="filterOption"
              show-search
              allowClear
              placeholder="选择卖点"
              mode="multiple"
              :loading="sellingLoading"
            >
              <a-select-option v-for="item in allSellingList" :key="item.value" :value="item.value">
                <a-icon type="user" v-show="item.unique" style="margin-right: 5px" />{{ item.value }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="2" style="margin-left: 10px; height: 40px; line-height: 40px; user-select: none">
            <div class="random-icon" @click="handleRandomTag">
              <a-popover>
                <template slot="content"> 摇一摇 </template>
                <img src="@/assets/icon/random_icon.png" alt="" />
              </a-popover>
            </div>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <div v-show="topic == 2" style="min-height: 536px">
      <div class="tag-line">
        <div class="tag-title">标签（选填）：</div>
        <div class="tag-body">
          <div class="tags">
            <div
              :class="['tags-1', { active1: value.isChoose }]"
              v-for="value in tagInfoList"
              :key="value.id"
              @click="handelTags1(value.id)"
            >
              {{ value.tagName }}
            </div>
          </div>
          <div class="tags">
            <div
              :class="['tags-2', { active2: value.isChoose }]"
              v-for="value in tagInfoChildList"
              :key="value.id"
              @click="handelTags2(value.id)"
            >
              {{ value.tagName }}
            </div>
          </div>
        </div>
      </div>
      <a-form-model style="margin-top: 30px" ref="substanceForm" :model="substanceForm">
        <a-form-model-item label="关键词（选填）">
          <a-input v-model="substanceForm.keywords" placeholder="多个关键词英文逗号分隔，不填则随机"></a-input>
        </a-form-model-item>
        <a-form-model-item label="干货条数">
          <a-input type="number" v-model="substanceForm.substanceCount" :min="1" :max="20" />
        </a-form-model-item>
        <div class="tag-line">
          <div class="tag-title">干货描述：</div>
          <div class="tag-body">
            <div class="tags">
              <div
                :class="['tags-2', { active2: item.isChoose }]"
                v-for="item in DESCRIPTION_LIST"
                :key="item.value"
                @click="handelTags3(item.value)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
    </div>

    <div v-show="topic == 3" style="min-height: 536px; margin-top: 30px">
      <div style="margin: 30px 0 15px">
        <div>
          输入小红书笔记ID或笔记链接<a-tooltip>
            <template slot="title">
              <p>笔记ID：从笔记分析、选题库、知识库复制</p>
              <p>笔记链接：小红书笔记分享链接</p> </template
            ><a-icon style="margin: 0 5px; line-height: 45px" type="question-circle" />
          </a-tooltip>
        </div>
        <div style="display: flex">
          <a-input style="width: 80%" v-model.trim="sourceNoteLink"></a-input>
          <a-button type="link" @click="getOriginNote" :loading="searchNoteLoading">获取笔记</a-button>
        </div>
        <div class="noteLine" v-show="searchNoteLoading">
          <a-icon type="loading" style="margin: 0 10px" />
          <div>笔记获取中...</div>
        </div>
        <div class="noteLine" v-show="showNoteFail">
          <div>笔记获取失败，请重试</div>
        </div>
        <div class="writtingPreview" v-if="noteDetail.noteId">
          <div class="previewLeft" :style="`background-image: url(${noteDetail.materialArr[0].previewUrl})`"></div>
          <div class="previewRight">
            <div class="rightTitle" :title="noteDetail.noteTitle">
              {{ noteDetail.noteTitle }}
            </div>
            <div class="rightId">
              <div class="rightIdL">ID：{{ noteDetail.noteId }}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin: 10px 0">
        <div><span style="color: red; margin-right: 5px">*</span>改写文案</div>
        <a-textarea :auto-size="{ minRows: 10, maxRows: 20 }" v-model="diyNoteText"></a-textarea>
      </div>
      <div>
        <div style="margin-bottom: 5px"><span style="color: red; margin-right: 5px">*</span>改写类型</div>
        <a-select v-model="rewriteType" option-filter-prop="children" style="width: 75%">
          <a-select-option v-for="types in rewriteTypeList" :value="types.id" :key="types.id">
            {{ types.aiRewriteName }}
          </a-select-option>
        </a-select>
      </div>
    </div>

    <!-- <div class="tag-line">
      <div class="tag-item" v-for="value in sellTagList" :key="value" @click="handleTagClick(value)">
        <div class="tag-text">{{ value }}</div>
        <a-icon type="close" />
      </div>
    </div> -->
    <a-row style="margin-top: 15px; padding-left: 15px; user-select: none" type="flex" align="middle">
      <a-col :span="6">
        <a-select v-show="false" v-model="listCount" option-filter-prop="children" style="width: 80px">
          <a-select-option v-for="(value, index) in [1, 2, 3, 4, 5]" :key="index" :value="value">
            {{ value }}篇
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="12">
        <a-button type="primary" style="width: 100%" @click="handleMakeStory" :loading="sellingLoading">生成</a-button>
      </a-col>
      <a-col :span="3" style="text-align: center" v-show="topic == 1">
        <!-- 灵感 -->
        <a-popover placement="rightBottom">
          <template slot="content"> Aha !</template>
          <div class="random-icon" @click="handleRandomAll" v-show="form.storyWay === 'MODEL'">
            <img src="@/assets/icon/inspire_icon.png" alt="" />
          </div>
        </a-popover>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { DESCRIPTION_LIST, CLASSIFY_LIST, WORD_COUNT_LIST, STORY_TYPE_MAP } from '../constant';
import api from '@/api/xhsAgencyApi';
import { debounce, cloneDeep } from 'lodash';
import { trackRequest } from '@/utils/track';

export default {
  props: {
    editData: {
      default: () => {},
    },
    principalInfoList: {
      default: () => [],
    },
    vehicleSeriesInfoList: {
      default: () => [],
    },
    topic: {
      default: () => undefined,
    },
    tagInfoList: {
      default: () => [],
    },
    tagInfoChildList: {
      default: () => [],
    },
    rewriteTypeList: {
      default: () => [],
    },
  },
  data() {
    return {
      substanceForm: {
        substanceCount: 6,
        describe: 'SIMPLE',
      },
      CLASSIFY_LIST,
      DESCRIPTION_LIST,
      WORD_COUNT_LIST,
      STORY_TYPE_MAP,
      storyTypeList: [],
      form: {
        storyWay: 'MODEL',
        wordCount: 150,
        customer: undefined,
        lifestyle: undefined,
        occupation: undefined,
        setting: undefined,
        features: [],
        storyType: 'kol',
        freeSetting: '',
        // secondSelling: undefined,
      },
      listCount: 1,
      customerList: [],
      lifestyleList: [],
      occupationList: [],
      settingList: [],
      allCustomerList: [],
      sellingList: [],
      // allSellingList: [],
      currencySellingList: [],
      // sellTagList: [],
      storyList: [],
      sellingLoading: false,
      sourceNoteLink: '',
      searchNoteLoading: false,
      showNoteFail: false,
      noteDetail: {},
      diyNoteText: '',
      rewriteType: null,
    };
  },
  computed: {
    allSellingList() {
      const list = [...this.sellingList, ...this.currencySellingList];
      return this.filterList(list);
    },
  },
  methods: {
    handelTags1(id) {
      this.tagInfoList.forEach((value) => {
        if (value.id === id) {
          value.isChoose = !value.isChoose;
        }
      });
    },
    handelTags2(id) {
      this.tagInfoChildList.forEach((value) => {
        if (value.id === id) {
          value.isChoose = !value.isChoose;
        }
      });
    },
    handelTags3(value) {
      DESCRIPTION_LIST.forEach((item) => {
        if (item.value === value) {
          item.isChoose = true;
        } else {
          item.isChoose = false;
        }
      });
      this.substanceForm.describe = value;
    },
    initContent() {
      // this.form.storyWay = 'MODEL';
      // this.form.wordCount = undefined;
      this.form.customer = undefined;
      this.form.lifestyle = undefined;
      this.form.occupation = undefined;
      this.form.setting = undefined;
      this.form.features = [];
      // this.form.storyType = undefined;
      this.customerList = [];
      this.lifestyleList = [];
      this.occupationList = [];
      this.settingList = [];
      this.allCustomerList = [];
      this.sellingList = [];

      // this.getAigcDictStoryType();
      this.getCurrentList();
      this.getCustomerTypeList();
    },

    // 获取通用卖点
    async getCurrentList() {
      const params = {
        seriesId: this.vehicleSeriesInfoList[0]?.vehicleSeriesId || '',
        occupation: '',
      };
      this.sellingLoading = true;
      await this.handleRequest(
        'getThirdSellingList',
        (data) => {
          this.currencySellingList = data.map((val) => ({ value: val, unique: false }));
        },
        params
      ).finally(() => {
        this.sellingLoading = false;
      });
    },
    filterOption(inputValue, option) {
      return option.componentOptions.children[1].text.indexOf(inputValue) > -1;
    },
    // 数据回显
    async getEditData() {
      // ---------------------------------------------------------------------------------------- 基础回显
      this.form.storyWay = this.editData.storyWay || 'MODEL';
      this.form.wordCount = this.editData.wordCount || undefined;
      this.form.storyType = this.editData.storyType || undefined;
      // this.getAigcDictStoryType();
      // ---------------------------------------------------------------------------------------- 人设回显
      this.form.customer = this.editData.customer || undefined;
      this.form.occupation = this.editData.occupation || undefined;
      this.form.lifestyle = this.editData.lifestyle || undefined;
      this.form.setting = this.editData.setting || undefined;
      // ---------------------------------------------------------------------------------------- 类型回显
      await this.getCustomerTypeList();
      // ---------------------------------------------------------------------------------------- 职业回显
      const occupationList = [];
      let restCustomerList = this.allCustomerList.filter((val) => val.customer === this.form.customer);
      restCustomerList.map((val) => {
        occupationList.push(val.occupation);
      });
      this.occupationList = [...new Set(occupationList)];
      // ---------------------------------------------------------------------------------------- 生活方式回显
      const lifestyleList = [];
      restCustomerList = restCustomerList.filter((val) => val.occupation === this.form.occupation);
      restCustomerList.map((val) => {
        lifestyleList.push(val.lifestyle);
      });
      this.lifestyleList = [...new Set(lifestyleList)];
      // ---------------------------------------------------------------------------------------- 使用场景回显
      const settingList = [];
      restCustomerList = restCustomerList.filter((val) => val.lifestyle === this.form.lifestyle);
      restCustomerList.map((val) => {
        settingList.push(val.setting);
      });
      this.settingList = [...new Set(settingList)];
      // ---------------------------------------------------------------------------------------- 卖点回显
      const features = this.editData.featureArr?.map((val) => val.thdFeature) || [];
      await this.getSellingPointList();
      this.form.features = features.length ? JSON.parse(JSON.stringify(features)) : [];
      // this.sellTagList = features.length ? JSON.parse(JSON.stringify(features)) : [];
    },
    // 获取卖点列表
    async getSellingPointList() {
      const params = {
        seriesId: this.vehicleSeriesInfoList[0]?.vehicleSeriesId || '',
        occupation: this.form.occupation || '',
      };
      if (!params.occupation) {
        this.sellingList = [];
        return;
      }
      console.log(params, '-----------------');
      this.sellingLoading = true;
      await this.handleRequest(
        'getThirdSellingList',
        (data) => {
          this.sellingList = data.map((val) => ({ value: val, unique: true }));
        },
        params
      ).finally(() => (this.sellingLoading = false));
    },
    filterList(tempArr) {
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = i + 1; j < tempArr.length; j++) {
          if (tempArr[i].value == tempArr[j].value) {
            tempArr.splice(j, 1);
            j--;
          }
        }
      }
      return tempArr;
    },
    // 故事视角
    // async getAigcDictStoryType() {
    //   const params = {
    //     creator: 'administrator',
    //     group: 'story_type',
    //     page: 1,
    //     pageSize: 99,
    //   };
    //   await this.handleRequest(
    //     'getAigcDictsList',
    //     (data) => {
    //       this.storyTypeList = data.list.map((item) => {
    //         return {
    //           label: STORY_TYPE_MAP[item.name],
    //           value: item.name,
    //         };
    //       });
    //     },
    //     params
    //   );
    // },
    // 获取人设列表
    async getCustomerTypeList() {
      const seriesId = this.vehicleSeriesInfoList[0]?.vehicleSeriesId || '';

      await this.handleRequest(
        'getCustomerTypeList',
        (data) => {
          this.allCustomerList = data;
          this.customerList = [...new Set(data.map((val) => val.customer))];
          if (!this.form.customer) {
            this.settingList = this.allCustomerList.map((val) => val.setting);
          }
        },
        {
          seriesId,
        }
      );
    },
    // 生成故事
    handleMakeStory: debounce(
      async function () {
        this.handleTrack('click_generate_text_btn');

        if (this.topic == 1) {
          if (!this.form.wordCount) return this.$message.error('请选择篇幅');
          if (!this.form.storyType) return this.$message.error('请选择故事视角');
          if (this.form.features.length == 0) return this.$message.error('请选择卖点');
          const isFree = this.form.storyWay === 'FREEDOM' ? true : false;

          // 模版模式
          const modelParams = {
            storyWay: this.form.storyWay,
            storyType: this.form.storyType,
            customer: this.form.customer,
            lifestyle: this.form.lifestyle,
            occupation: this.form.occupation,
            wordCount: this.form.wordCount,
            features: this.form.features.map((val) => ({ thdFeature: val })),
            principalId: this.principalInfoList[0]?.principalId || '',
            principalName: this.principalInfoList[0]?.principalName || '',
            seriesId: this.vehicleSeriesInfoList[0]?.vehicleSeriesId || '',
            seriesName: this.vehicleSeriesInfoList[0]?.vehicleSeriesName || '',
            setting: this.form.setting,
          };
          if (!modelParams.features.length) modelParams.features = null;

          // 自由模式
          const freeParams = {
            storyWay: this.form.storyWay,
            storyType: this.form.storyType,
            wordCount: this.form.wordCount,
            principalId: this.principalInfoList[0]?.principalId || '',
            principalName: this.principalInfoList[0]?.principalName || '',
            seriesId: this.vehicleSeriesInfoList[0]?.vehicleSeriesId || '',
            seriesName: this.vehicleSeriesInfoList[0]?.vehicleSeriesName || '',
            setting: this.form.freeSetting,
          };

          // 参数校验
          if (isFree && !freeParams.setting) return this.$message.error('请输入内容');

          // 把参数同步到父组件
          const updateData = isFree ? freeParams : modelParams;
          this.$emit('getStory', updateData);
        } else if (this.topic == 2) {
          // 处理标签
          let tagList = [];
          if (this.tagInfoList.length != 0) {
            this.tagInfoList.forEach((val) => {
              if (val.isChoose) {
                tagList.push(val.tagName);
              }
            });
          }
          if (this.tagInfoChildList.length != 0) {
            this.tagInfoChildList.forEach((val) => {
              if (val.isChoose) {
                tagList.push(val.tagName);
              }
            });
          }
          const substanceParams = {
            describe: this.substanceForm.describe,
            keywords: this.substanceForm.keywords,
            substanceCount: this.substanceForm.substanceCount,
            tagNameList: tagList,
          };
          this.$emit('getStory', substanceParams);
        } else if (this.topic == 3) {
          let params = {
            aiRewriteToolId: this.rewriteType,
            content: this.diyNoteText.trim(),
          };
          if (!this.rewriteType) return this.$message.error('请先选取改写类型');
          if (!this.diyNoteText.trim()) return this.$message.error('请输入文案');
          this.$emit('getDiyStory', params);
        }

        // if (this.listCount > 1) {
        //   this.handlePatchRequest(this.listCount, params)
        //     .then((data) => {
        //       console.log(data, 'get');
        //     })
        //     .finally(() => console.log(this.storyList, 'list~~~~'));
        // }
        // this.btnLoading = true;
        // this.handleRequest(
        //   'getNewAiStory',
        //   (data) => {
        //     // 同步参数以及生成后的故事/角色到父组件
        //     const { role, story } = data;
        //     // if (isRandomFeatureDescription) this.$message.info('该生成故事含部分随机元素');
        //     updateData.role = role;
        //     updateData.story = story;
        //     this.$emit('getStory', updateData);
        //   },
        //   params
        // ).finally(() => {
        //   this.btnLoading = false;
        // });
      },
      1000,
      { leading: true }
    ),
    handlePatchRequest(requestCount, params) {
      const _this = this;
      const promisesList = []; // 请求队列
      const resultsList = new Array(requestCount); // 请求结果队列

      // 创建请求
      for (let i = 0; i < requestCount; i++) {
        const promise = new Promise((resolve, reject) => {
          api
            .getNewAiStory(params)
            .then((data) => resolve(data))
            .catch((err) => reject(err));
        });
        promisesList.push(promise);
      }

      promisesList.forEach((promise, index) => {
        promise
          .then((response) => {
            resultsList[index] = response;
            // 请求成功加到保存队列
            if (response.code === 200) {
              _this.storyList.push({
                role: response.data.role,
                story: response.data.story,
              });
              console.log(_this.storyList, 'here~');
            }
          })
          .catch((error) => {
            // 处理请求错误
            _this.$message.error(`生成故事失败: ${error}`);
          });
      });

      return new Promise((resolve) => {
        // 检查所有请求是否都完成
        let completedCount = 0;

        function checkPromiseComplete() {
          if (completedCount === requestCount) {
            resolve(resultsList);
          }
        }

        promisesList.forEach((promise) => {
          promise.finally(() => {
            completedCount++;
            checkPromiseComplete();
          });
        });
      });
    },
    // --------------------------------------------------------------- 下拉相关 start
    // 更改客户类型
    handleCustomerChange(value) {
      this.form.occupation = undefined;
      this.form.lifestyle = undefined;
      this.form.setting = undefined;
      this.lifestyleList = [];
      this.settingList = [];
      this.sellingList = [];
      // this.sellTagList = [];
      this.form.features = [];
      this.getSellingPointList();
      if (value) {
        const restCustomerList = this.allCustomerList.filter((val) => val.customer === this.form.customer);
        const occupationList = [];
        restCustomerList.forEach((val) => {
          occupationList.push(val.occupation);
        });
        this.occupationList = [...new Set(occupationList)];
      } else {
        this.occupationList = [];
        // 重新拉取使用场景
        this.settingList = this.allCustomerList.map((val) => val.setting);
      }
    },
    // 更改职业
    handleOccupationChange(value) {
      this.form.lifestyle = undefined;
      this.form.setting = undefined;
      this.settingList = [];
      this.sellingList = [];
      // this.sellTagList = [];
      this.form.features = [];
      this.getSellingPointList();
      if (value) {
        const lifestyleList = [];
        const restCustomerList = this.allCustomerList
          .filter((val) => val.customer === this.form.customer)
          .filter((val) => val.occupation === this.form.occupation);
        restCustomerList.map((val) => {
          lifestyleList.push(val.lifestyle);
        });
        this.lifestyleList = [...new Set(lifestyleList)];
      } else {
        this.lifestyleList = [];
      }
    },
    // 更改生活方式
    handleLifestyleChange(value) {
      // this.sellingList = [];
      this.form.setting = undefined;
      // this.form.features = [];
      if (value) {
        const settingList = [];
        const restCustomerList = this.allCustomerList
          .filter((val) => val.customer === this.form.customer)
          .filter((val) => val.occupation === this.form.occupation)
          .filter((val) => val.lifestyle === this.form.lifestyle);
        restCustomerList.map((val) => {
          settingList.push(val.setting);
        });
        this.settingList = [...new Set(settingList)];
      } else {
        this.settingList = [];
      }
      // this.sellTagList = [];
      // this.form.features = [];
      // this.form.lifestyle = undefined;
    },
    // 更改使用场景
    handleSettingChange(value) {
      if (value && !this.form.customer) {
        // 场景反选人设
        const item = this.allCustomerList.find((val) => val.setting === value);
        this.form.customer = item.customer;
        this.form.occupation = item.occupation;
        this.form.lifestyle = item.lifestyle;
        const occupationList = [];
        let restCustomerList = this.allCustomerList.filter((val) => val.customer === this.form.customer);
        restCustomerList.map((val) => {
          occupationList.push(val.occupation);
        });
        this.occupationList = [...new Set(occupationList)];
        const lifestyleList = [];
        restCustomerList = restCustomerList.filter((val) => val.occupation === this.form.occupation);
        restCustomerList.map((val) => {
          lifestyleList.push(val.lifestyle);
        });
        this.lifestyleList = [...new Set(lifestyleList)];
        const settingList = [];
        restCustomerList = restCustomerList.filter((val) => val.lifestyle === this.form.lifestyle);
        restCustomerList.map((val) => {
          settingList.push(val.setting);
        });
        this.settingList = [...new Set(settingList)];
        // 更新卖点列表
        this.getSellingPointList();
      }
    },
    // --------------------------------------------------------------- 下拉相关 end
    // 随机获取卖点
    handleRandomTag() {
      if (this.form.features.length === this.allSellingList.length) return this.$message.info('已无更多可添加卖点');
      // 剩余可随机列表
      let Customer = [];
      if (!this.form.features.length) {
        Customer = JSON.parse(JSON.stringify(this.allSellingList));
      } else {
        this.allSellingList.forEach((value) => {
          const index = this.form.features.findIndex((val) => val === value.value);
          if (index === -1) Customer.push(value);
        });
      }
      // 获取随机元素
      // const randomNum = Customer.length - 1;
      // const randomIndex = Math.ceil(Math.random() * randomNum);
      // const randomItem = Customer[randomIndex].value;
      this.form.features.push(this.getRandomItem(Customer, 'value'));
    },
    // 全部随机
    handleRandomAll: debounce(
      async function () {
        this.handleTrack('click_aha_btn');
        if (this.sellingLoading) return this.$message.info('请稍等');
        if (!this.vehicleSeriesInfoList.length) return this.$message.info('请先选择品牌和车系');
        if (!this.allCustomerList.length) return this.$message.info('该车系没有可用人设');
        // this.form = this.$options.data().form;
        // ---------------------------------------------------------------------------------------- 随机基础
        // this.form.wordCount = this.getRandomItem('WORD_COUNT_LIST', 'value');
        // this.form.storyType = this.getRandomItem('storyTypeList', 'value');
        // ---------------------------------------------------------------------------------------- 随机类型
        this.form.customer = this.getRandomItem(this.customerList);
        // ---------------------------------------------------------------------------------------- 随机职业
        const occupationList = [];
        let restCustomerList = this.allCustomerList.filter((val) => val.customer === this.form.customer);
        restCustomerList.map((val) => {
          occupationList.push(val.occupation);
        });
        this.occupationList = [...new Set(occupationList)];
        this.form.occupation = this.getRandomItem(this.occupationList);
        // ---------------------------------------------------------------------------------------- 随机生活方式
        const lifestyleList = [];
        restCustomerList = restCustomerList.filter((val) => val.occupation === this.form.occupation);
        restCustomerList.map((val) => {
          lifestyleList.push(val.lifestyle);
        });
        this.lifestyleList = [...new Set(lifestyleList)];
        this.form.lifestyle = this.getRandomItem(this.lifestyleList);
        // ---------------------------------------------------------------------------------------- 随机使用场景
        const settingList = [];
        restCustomerList = restCustomerList.filter((val) => val.lifestyle === this.form.lifestyle);
        restCustomerList.map((val) => {
          settingList.push(val.setting);
        });
        this.settingList = [...new Set(settingList)];
        this.form.setting = this.getRandomItem(this.settingList);
        // ---------------------------------------------------------------------------------------- 随机卖点
        if (this.currencySellingList.length) {
          this.form.features = [];
          this.sellingList = [];
          await this.getSellingPointList().finally(() => {
            let randomList = [];
            // 获取随机元素
            if (this.sellingList.length) {
              // ---------------------------------------------------------------------------------------- 随机1个职业卖点
              randomList.push(this.getRandomItem(this.sellingList, 'value'));
            }
            // ---------------------------------------------------------------------------------------- 随机3个通用卖点
            const randomSellingList = this.getRandomSelling([], cloneDeep(this.currencySellingList));
            this.form.features = [...randomList, ...randomSellingList].splice(0, 3); // 截取前三个
          });
        }

        // this.handleMakeStory();
      },
      500,
      { leading: true }
    ),
    // 获取随机通用卖点
    getRandomSelling(itemList, cloneList) {
      if (itemList.length < 3) {
        const deepList = cloneDeep(cloneList);
        const randomNum = deepList.length;
        const randomIndex = Math.ceil(Math.random() * randomNum) - 1;
        itemList.push(deepList[randomIndex].value);
        deepList.splice(randomIndex, 1);
        return this.getRandomSelling(itemList, deepList);
      } else {
        // const returnList = [];
        // itemList.map((val) => {
        //   returnList.push(cloneList[val]);
        // });
        // console.log(returnList, '-------fixbug');
        // const returnItemList = returnList.length ? returnList.map((val) => val.value) : [];
        return itemList;
      }
    },
    // 获取随机
    getRandomItem(LIST, CHILD = null) {
      if (!LIST.length) return undefined;
      const randomNum = LIST.length;
      const randomIndex = Math.ceil(Math.random() * randomNum) - 1;
      if (!LIST[randomIndex]) return undefined;
      const randomItem = CHILD ? LIST[randomIndex][CHILD] : LIST[randomIndex];
      return randomItem || undefined;
    },
    handleModeChange(type) {
      this.handleTrack('toggle_input_type', {
        type: type == 'MODEL' ? 'template' : 'free',
      });
      if (this.sellingLoading) return this.$message.info('等待服务器返回中，请稍后');
      // const mode = this.form.storyWay
      // this.form = this.$options.data().form;
      // this.form.storyWay = mode
      this.form.storyWay = type;
      // this.form.storyWay === 'MODEL' && this.getSellingPointList();
    },
    // 删除tag
    handleTagClick(value) {
      // const index = this.form.features.findIndex((val) => val === value);
      // this.form.features.splice(index, 1);
      // this.sellTagList = [];
      // this.form.features.forEach((val) => {
      //   this.sellTagList.push(val);
      // });
    },
    // ---------------------------------------------------------------- 自定义相关 start
    getOriginNote() {
      this.handleTrack('get_note');

      if (!this.sourceNoteLink) return this.$message.error('请输入笔记ID或链接');
      this.showNoteFail = false;
      this.searchNoteLoading = true;
      this.noteDetail = {};
      api
        .getOriginXhsNote({ noteParam: this.sourceNoteLink })
        .then(({ code, data, message }) => {
          if (code == 200) {
            if (!data.length) return (this.showNoteFail = true);
            this.noteDetail = data[0];
            this.diyNoteText = data[0].noteDetail;
          } else {
            this.$message.error(message);
          }
        })
        .catch(() => (this.showNoteFail = true))
        .finally(() => (this.searchNoteLoading = false));
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    getTagName(id) {
      return this.sellingList.find((val) => val.value === id).text;
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
  },
};
</script>

<style lang="less" scoped>
.tag-line {
  .tag-title {
    padding-top: 5px;
    margin-bottom: 10px;
    margin-top: 30px;
    width: 100%;
    color: #262626;
  }

  .tag-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #2d2d2d;
    .tags {
      display: flex;
      .tags-1 {
        padding: 3px 5px;
        margin: 0 10px 10px 0;
        border-radius: 6px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.397);
        color: white;
      }
      .tags-2 {
        padding: 3px 5px;
        margin: 0 10px 10px 0;
        border-radius: 6px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.65);
        color: rgba(0, 0, 0, 0.65);
      }
      .active1 {
        background-color: #1890ff;
        color: #fff;
      }
      .active2 {
        border: 1px solid #1890ff;
        color: #1890ff;
      }
    }
  }
}
.top {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 10px;

  .top-right {
    color: #309bff;
    cursor: pointer;
  }
}
/deep/ .ant-form-item {
  margin-bottom: 15px !important;
}

.random-icon {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}

// .tag-line {
//   display: flex;
//   flex-wrap: wrap;
//   padding-left: 15px;

//   .tag-item {
//     margin: 0 15px 15px 0;
//     padding: 0 8px;
//     display: flex;
//     align-items: center;
//     color: #797979;
//     border: 1px #797979 solid;
//     cursor: pointer;
//     border-radius: 5px;

//     .tag-text {
//       max-width: 100px;
//       margin-right: 5px;
//       overflow: hidden;
//       white-space: nowrap;
//       text-overflow: ellipsis;
//     }
//   }
// }

.noteLine {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 0 10px;
  height: 70px;
  background-color: #f2f2f2;
}

.writtingPreview {
  display: flex;
  margin: 10px 0;
  background-color: #f2f2f2;

  .previewLeft {
    margin-right: 5px;
    width: 50px;
    height: 50px;
    background-color: #80808033;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
  }

  .previewRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .rightTitle {
      // width: 200px;
      font-size: 600;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    .rightId {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ae7983;
    }
  }
}
</style>
<style lang="less">
.customer-dropdown-options {
  white-space: normal !important;
  text-overflow: clip !important;
}
</style>
