<template>
  <div style="display: flex">
    <div class="left">
      <div class="left-item">
        <a-tabs
          class="search-bottom"
          type="card"
          v-model="currentTab"
          @change="handleTags"
          :tabBarGutter="10"
          size="large"
        >
          <a-tab-pane :key="1" tab="种草">
            <a-form-model v-show="form1.topic == 1" style="margin-top: 30px" ref="addForm" :model="addFormState">
              <a-form-model-item label="品牌">
                <a-row type="flex" justify="space-around">
                  <a-col :span="11">
                    <a-select
                      v-model="addFormState.principalId"
                      placeholder="品牌"
                      show-search
                      option-filter-prop="children"
                      @change="handleAddPrincipalChange"
                      :maxTagCount="3"
                    >
                      <a-select-option v-for="item in principalList" :key="item.value" :value="item.value">{{
                        item.label
                      }}</a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="11">
                    <a-select
                      :loading="seriesLoading"
                      v-model="addFormState.vehicleSeriesId"
                      placeholder="车系"
                      show-search
                      option-filter-prop="children"
                      @change="seriesChange"
                    >
                      <a-select-option v-for="item in addSeriesList" :key="item.id">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-form-model>
          </a-tab-pane>
          <a-tab-pane :key="2" tab="干货"></a-tab-pane>
          <a-tab-pane :key="3" tab="自定义"></a-tab-pane>
        </a-tabs>

        <!-- 内容需求 -->
        <ContentComp
          ref="ContentComp"
          :principalInfoList="principalInfoList"
          :vehicleSeriesInfoList="vehicleSeriesInfoList"
          @getStory="getStory"
          @getDiyStory="getDiyStory"
          :editData="contentCompEditData"
          :topic="form1.topic"
          :tagInfoList="tagInfoList"
          :tagInfoChildList="tagInfoChildList"
          :rewriteTypeList="rewriteTypeList"
        />
      </div>
    </div>

    <div class="right" ref="article">
      <!-- 生成文案列表 -->
      <div class="story-item" v-for="(storyData, index) in storyList" :key="index">
        <!-- 顶部 -->
        <div class="item-top" v-if="storyData.isDone && !storyData.showError">
          <div class="top-left" @click="handleOpenDrawer(storyData)">
            <div v-for="(item, index) in storyData.makeStoryParams.featuresView" :key="index" class="header-overflow">
              {{ item.feature_code }} - {{ item.thdFeature }}：{{ item.featureDesc }}
            </div>
            <div v-if="!storyData.postParams.storyType">
              <div v-for="(item, index) in storyData.makeStoryParams.setting" :key="index" class="header-overflow">
                {{ item }}
              </div>
            </div>
            <a-icon type="arrows-alt" style="position: absolute; right: 10px; top: 10px; font-size: 20px" />
          </div>
          <div class="top-right"></div>
        </div>
        <a-alert
          v-if="storyData.isNoteCheckError"
          style="margin-right: 50px"
          message="文案中存在高风险词，已高亮处理，请修改"
          banner
          closable
          @close="storyData.isNoteCheckError = false"
        />
        <!-- 中间 -->
        <div class="item-middle">
          <div :class="['item-middle-left', { 'all-around': !storyData.isDone || storyData.showError }]">
            <!-- 标题 -->
            <!-- 展示标题 -->
            <div v-show="storyData.title && !storyData.showEdit" class="left-input">
              <div class="only-read-title" v-if="!storyData.showEdit">{{ storyData.readTitle || storyData.title }}</div>
              <div class="border-bottom" v-if="!storyData.showEdit && storyData.title"></div>
              <div
                :class="['word-count', { red: storyData.title.length > 20 }]"
                v-show="storyData.title || storyData.showEdit"
              >
                {{ storyData.title.length || 0 }} / 20
                <a-icon v-show="storyData.title.length > 20" type="exclamation" />
              </div>
            </div>
            <!-- 编辑标题 -->
            <div style="position: relative" v-if="storyData.showEdit">
              <a-textarea
                class="input-class"
                placeholder="笔记标题"
                :auto-size="{ maxRows: 3 }"
                v-model="storyData.title"
                :disabled="!storyData.showEdit"
              >
              </a-textarea>
              <div
                style="position: absolute; top: 5px; right: 30px"
                :class="['word-count', { red: storyData.title.length > 20 }]"
              >
                {{ storyData.title.length || 0 }} / 20
                <a-icon v-show="storyData.title.length > 20" type="exclamation" />
              </div>
            </div>
            <!-- AI文章展示 -->
            <div class="only-read-text" v-if="!storyData.showEdit">
              <a-spin :spinning="storyData.loading">
                <div v-html="storyData.aiStory"></div>
              </a-spin>
              <span class="blink" v-show="!storyData.isDone"></span>
              <div class="only-read-word" v-show="storyData.aiStory">
                字数： {{ storyData.aiStory ? storyData.aiStory.length : 0 }}
              </div>
            </div>
            <div
              style="color: red; font-weight: bold; font-size: 18px; padding: 20px; margin-bottom: 30px"
              v-if="storyData.showError"
            >
              生成文案出错：{{ storyData.errorMsg
              }}<a-button type="link" @click="handleDel(storyData.timeStr)">删除</a-button>
            </div>
            <!-- 编辑器 -->
            <div v-show="storyData.showEdit" id="wangeditor">
              <div
                :ref="`editorElem${storyData.timeStr}`"
                :id="`editorElem${storyData.timeStr}`"
                class="editor-style"
              ></div>
            </div>
            <div class="text-bottom" v-if="storyData.showEdit">
              <div style="display: flex; color: #999999">
                <a-popover placement="topRight">
                  <template slot="content">
                    <p>小红书对特殊符号的计数较混乱，此处超过900字后，</p>
                    <p>在小红书发布时需检测笔记是否完整。</p>
                  </template>
                  <span style="cursor: pointer">
                    <a-icon type="question-circle" style="margin-right: 5px" />小红书计数规则
                  </span>
                </a-popover>
                <span style="margin: 0 5px" :class="[{ red: storyData.editWordCount > 900 }]"
                  >{{ storyData.editWordCount || 0 }}/1000</span
                >
              </div>
            </div>
          </div>
          <!-- 操作 -->
          <template>
            <div class="item-middle-right" v-if="storyData.isDone && !storyData.showError">
              <a-card
                :id="`title_${storyData.timeStr}`"
                style="right: 50px; width: 360px; min-height: 140px; position: absolute; z-index: 9999"
                v-show="storyData.showAiTitle"
              >
                <div style="margin-bottom: 15px">
                  <div :style="{ borderBottom: '1px solid #E9E9E9', marginBottom: '10px' }">选择想要的标题</div>
                  <a-radio-group v-model="storyData.titleByAi" @change="() => {}">
                    <div style="display: flex; flex-direction: column">
                      <a-radio
                        v-for="(titles, tIndex) in storyData.aiTitleList"
                        :value="titles"
                        :key="tIndex"
                        style="margin-bottom: 5px"
                      >
                        {{ index + 1 }}. {{ titles }}
                      </a-radio>
                    </div>
                  </a-radio-group>
                </div>
                <div style="display: flex; justify-content: flex-end">
                  <a-space>
                    <a-button @click="handleTitleClose(storyData)">关闭</a-button>
                    <a-button @click="confirmTitle(storyData)">确认</a-button>
                  </a-space>
                </div>
              </a-card>
              <a-card
                :id="`tags_${storyData.timeStr}`"
                style="right: 50px; width: 360px; min-height: 140px; position: absolute; z-index: 9999"
                v-show="storyData.showCheckTag"
              >
                <template>
                  <div style="margin-bottom: 15px">
                    <div :style="{ borderBottom: '1px solid #E9E9E9', marginBottom: '10px' }">
                      <div style="margin-bottom: 10px">选择想要加入到笔记中的话题</div>
                      <a-checkbox
                        :indeterminate="storyData.indeterminate"
                        :checked="storyData.checkAllTag"
                        @change="(e) => onCheckAllTag(e, storyData)"
                      >
                        全选
                      </a-checkbox>
                    </div>
                    <a-checkbox-group
                      v-model="storyData.tagCheckedList"
                      :options="storyData.tagOptionList"
                      @change="(val) => onTagChange(val, storyData)"
                    >
                    </a-checkbox-group>
                  </div>
                  <div style="display: flex; justify-content: flex-end">
                    <a-space>
                      <a-button @click="handleTagClose(storyData)">关闭</a-button>
                      <a-button @click="confirmAddTag(storyData)">确认</a-button>
                    </a-space>
                  </div>
                </template>
              </a-card>
              <div v-show="!storyData.showEdit">
                <div class="right-item">
                  <a-popover placement="leftBottom">
                    <template slot="content"> 编辑 </template>
                    <a-icon style="font-size: 19px" type="edit" @click="handleStoryEdit(storyData)" />
                  </a-popover>
                </div>
                <a-popover placement="left" trigger="click">
                  <template slot="content">
                    <div style="display: flex; flex-direction: column">
                      <a-button :loading="storyData.loading" type="link" @click="addEmoji(storyData)">+表情</a-button>
                      <a-button
                        :loading="storyData.loading"
                        type="link"
                        style="margin: 10px 0"
                        @click="addTitle(storyData)"
                        >+标题</a-button
                      >
                      <a-button :loading="storyData.loading" type="link" @click="addHashTag(storyData)">+话题</a-button>
                      <a-button :loading="storyData.loading" type="link" @click="automaticLineWrapping(storyData)"
                        >自动分行</a-button
                      >
                    </div>
                  </template>
                  <div class="right-item"><a-icon style="font-size: 19px; cursor: pointer" type="smile" /></div>
                </a-popover>
                <div class="right-item">
                  <a-popover placement="leftBottom">
                    <template slot="content"> 保存 </template>
                    <a-icon style="font-size: 19px" type="save" @click="handleSave(storyData, 'save')" />
                  </a-popover>
                </div>
                <div class="right-item" @click="handleSave(storyData, 'publish')">
                  <a-popover placement="leftBottom">
                    <template slot="content"> 发布 </template>
                    <img style="cursor: pointer" src="@/assets/icon/sent_logo.png" alt="" />
                  </a-popover>
                </div>
                <div class="right-item">
                  <a-popover placement="leftBottom">
                    <template slot="content"> 文转图 </template>
                    <a-icon style="font-size: 19px" type="picture" @click="handleTextToImg(storyData)" />
                  </a-popover>
                </div>
              </div>
              <div v-show="storyData.showEdit">
                <div class="right-item" @click="handleEditConfirm(storyData)">
                  <a-icon style="font-size: 19px" type="check" />
                </div>
                <div class="right-item" @click="handleEditClose(storyData)">
                  <a-icon style="font-size: 19px" type="close" />
                </div>
              </div>
            </div>
            <div v-else class="item-middle-right"></div>
          </template>
        </div>

        <!-- 生成中 -->
        <div class="item-bottom" v-show="!storyData.isDone">
          <div style="display: flex; align-items: center">
            <div style="color: #aaaaaa; font-weight: bold">
              <a-icon type="loading" style="margin-right: 10px" />
              正在生成
            </div>
            <!-- <a-button type="link" @click="handleStop(storyData)" v-show="!storyData.isDone">停止</a-button> -->
          </div>
        </div>

        <!-- 话题 -->
        <div class="tag-line" v-show="storyData.showEdit || storyData.tagList.length">
          <div style="width: 50px">话题：</div>
          <div style="flex: 1">
            <a-tag
              v-for="tag in storyData.tagList"
              :key="tag.id"
              style="margin-bottom: 5px"
              color="blue"
              :closable="storyData.showEdit"
              @close="() => handleClose(tag.id, storyData)"
            >
              <span v-show="tag.isHot">🔥</span> {{ tag.name }}
            </a-tag>
            <a-tag
              v-if="!storyData.showHashTag && storyData.showEdit"
              style="borderstyle: dashed"
              @click="handleAddTagClick(storyData)"
            >
              <a-icon type="plus" /> 话题</a-tag
            >
            <div style="display: flex; align-items: center" v-if="storyData.showHashTag">
              <a-select
                v-model="storyData.newHashTag"
                placeholder="请输入话题搜索"
                style="width: 380px"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                @search="(value) => handleHashTagSearch(value, storyData)"
                @change="(value) => handleHashTagChange(value, storyData)"
                @blur="(value) => handleHashTagBlur(value, storyData)"
              >
                <a-select-option v-for="value in storyData.searchTagList" :key="value.id" :value="value.id">
                  <div style="display: flex; justify-content: space-between">
                    <div>{{ value.name }}</div>
                    <div style="color: #bfc3c9; font-size: 12px">{{ common.numEdit(value.viewNum) }}次浏览</div>
                  </div>
                </a-select-option>
              </a-select>
              <a-icon type="loading" style="margin-left: 10px; color: #0000ff69" v-show="storyData.hashTagLoading" />
              <div style="margin-left: 10px" v-show="storyData.showTip">
                无匹配结果，请修改重试或前往小红书App新建该话题
              </div>
            </div>
          </div>
        </div>

        <!-- 质量评价 -->
        <div class="item-bottom" v-show="storyData.isDone && !storyData.hasRate">
          <div style="display: flex; align-items: center">
            <a-icon
              type="smile"
              style="font-size: 28px; cursor: pointer; color: #66525c"
              @click="handleRate(1, storyData)"
            />
            <a-icon
              type="frown"
              style="margin: 0 20px; font-size: 28px; cursor: pointer; color: #66525c"
              @click="handleRate(-1, storyData)"
            />
            <span style="color: #aaaaaa">生成质量如何?</span>
          </div>
        </div>
      </div>
    </div>

    <a-drawer
      placement="top"
      :closable="true"
      :visible="showDrawer"
      @close="showDrawer = false"
      height="600px"
      :destroyOnClose="true"
    >
      <template slot="title">
        <div v-if="showStoryData.postParams.storyType" style="font-size: 20px; font-weight: bold">
          {{ showStoryData.postParams.principalName }} {{ showStoryData.postParams.seriesName }}
        </div>
      </template>
      <div v-if="showStoryData.postParams.storyType">
        <div style="font-weight: bold; font-size: 20px">基础</div>
        <div>篇幅：{{ showStoryData.postParams.wordCount }}字内</div>
        <div>
          故事视角：<span v-if="showStoryData.postParams.storyType == 'kol'">{{ '车主种草' }}</span
          ><span v-if="showStoryData.postParams.storyType == 'pickup'">{{ '车主提车' }}</span
          ><span v-if="showStoryData.postParams.storyType == 'other'">{{ '经销商种草' }}</span>
        </div>
      </div>
      <div v-if="showStoryData.postParams.storyType">
        <div class="drawer-title">人设</div>
        <div>
          <div>
            <span v-if="showStoryData.makeStoryParams.customer"
              >客户类型：{{ showStoryData.makeStoryParams.customer }}</span
            >
            <span v-if="showStoryData.makeStoryParams.occupation" style="margin-left: 20px"
              >职业：{{ showStoryData.makeStoryParams.occupation }}</span
            >
          </div>
          <div v-if="showStoryData.makeStoryParams.lifestyle">
            生活方式：{{ showStoryData.makeStoryParams.lifestyle }}
          </div>
          <span v-if="showStoryData.makeStoryParams.setting"> 场景：{{ showStoryData.makeStoryParams.setting }} </span>
        </div>
      </div>
      <div v-if="showStoryData.postParams.storyType">
        <div class="drawer-title">卖点</div>
        <div v-for="(item, index) in showStoryData.makeStoryParams.features" :key="index">
          <span style="color: #4e8bdd; margin-right: 5px">{{ index + 1 }}.</span>{{ item.feature_code }}-{{
            item.thdFeature
          }}：{{ item.featureDesc }}
        </div>
      </div>
      <div v-if="!showStoryData.postParams.storyType">
        <div style="font-weight: bold; font-size: 20px">内容需求</div>
        <div>选题方向：干货</div>
        <div>
          分类：<span v-for="(item, index) in showStoryData.postParams.tagNameList" :key="index">{{ item }}</span>
        </div>
        <div>关键词：{{ showStoryData.postParams.keywords }}</div>
        <div>干货数量：{{ showStoryData.postParams.substanceCount }}</div>
        <div>
          干货描述：<span v-if="showStoryData.postParams.describe == 'SIMPLE'">{{ DESCRIPTION_LIST[0].label }}</span>
          <span v-else>{{ DESCRIPTION_LIST[1].label }}</span>
        </div>
      </div>
      <div v-if="!showStoryData.postParams.storyType">
        <div style="font-weight: bold; font-size: 20px">Setting</div>
        <div>
          <div v-for="(item, index) in showStoryData.makeStoryParams.setting" :key="index">
            {{ index + 1 }}. {{ item }}
          </div>
        </div>
      </div>
      <div>
        <div class="drawer-title">生成计划</div>
        <div style="white-space: pre-wrap">{{ showStoryData.makeStoryParams.plan }}</div>
      </div>
      <div>
        <div class="drawer-title">故事</div>
        <div style="white-space: pre-wrap">{{ showStoryData.makeStoryParams.story }}</div>
      </div>
    </a-drawer>

    <!-- 保存弹窗 -->
    <a-modal
      v-model="showFirstSave"
      title="保存笔记"
      :confirmLoading="saveLoading"
      @ok="handleSaveOk"
      @cancel="handleSaveCancel"
    >
      <div>输入便于记忆的名称</div>
      <div style="position: relative">
        <a-input
          :placeholder="saveParams.noteDetail.slice(0, 15)"
          :maxLength="15"
          style="margin: 5px 0; padding-right: 30px"
          v-model="saveParams.name"
        >
        </a-input>
        <span style="position: absolute; top: 9px; right: 15px; color: #9f9f9f; z-index: 1"
          >{{ saveParams.name ? saveParams.name.length : 0 }} / 15</span
        >
      </div>
    </a-modal>
    <!-- 品牌车系 -->
    <a-modal v-model="showAddModal" title="选择品牌车系" @cancel="handleAddCancel">
      <a-form-model
        style="margin-top: 30px"
        ref="addForm1"
        :model="addFormState"
        :rules="addRules"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-model-item label="品牌" prop="principalId">
          <a-select
            v-model="addFormState.principalId"
            placeholder="请选择品牌"
            show-search
            option-filter-prop="children"
            @change="handleAddPrincipalChange"
            :maxTagCount="3"
            allowClear
          >
            <a-select-option v-for="item in principalList" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车系" prop="vehicleSeriesId">
          <a-select
            :loading="seriesLoading"
            v-model="addFormState.vehicleSeriesId"
            placeholder="请选择车系"
            show-search
            option-filter-prop="children"
            allowClear
          >
            <a-select-option v-for="item in addSeriesList" :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="handleAddCancel"> 取消 </a-button>
        <a-button key="submit" type="primary" @click="handleAddOk"> 下一步 </a-button>
      </template>
    </a-modal>

    <!-- 查看/编辑抽屉 -->
    <writtingDrawer
      v-if="showPublishDrawer"
      :readOnly="false"
      @closeDrawer="showPublishDrawer = false"
      :drawerEditData="drawerEditData"
      :isLocalData="true"
    />
  </div>
</template>

<script>
import PicComp from './components/PicComp';
import StoryComp from './components/StoryComp';
import ContentComp from './components/ContentComp';
import NoteComp from './components/NoteComp';
import PublishComp from './components/PublishComp';
import MessageTextCompVue from '@/components/MessageTextComp.vue';
import api from '@/api/xhsAgencyApi';
import { cloneDeep } from 'lodash';
import { EMOJI_LIST, STORY_TYPE_MAP, TOPIC_LIST, DESCRIPTION_LIST } from './constant';
import E from 'afantiEditor';
// import { auth } from '@/utils';
import utils from '@/utils/Tool';
import writtingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';
import { handleNoteCheck } from '../utils';

import { trackRequest } from '@/utils/track';

// import { fetchEventSource } from '@microsoft/fetch-event-source';

// import axios from 'axios';

export default {
  name: 'makeAiStory',
  components: { PicComp, StoryComp, ContentComp, NoteComp, PublishComp, writtingDrawer, MessageTextCompVue },
  data() {
    const addRules = {
      principalId: [{ required: true, message: '品牌不能为空', trigger: 'change' }],
      vehicleSeriesId: [{ required: true, message: '车系不能为空', trigger: 'change' }],
    };
    return {
      myType: '',
      storyIndex: {},
      addRules,
      showAddModal: false,
      form2: {},
      principalAndSeriesModel: true,
      DESCRIPTION_LIST,
      addFormState: {
        principalId: undefined,
        vehicleSeriesId: undefined,
      },
      form1: {
        topic: 1,
      },
      principalList: [],
      addSeriesList: [],
      tagInfoList: [],
      tagInfoChildList: [],
      rewriteTypeList: [],
      seriesLoading: false,
      principalInfoList: [],
      vehicleSeriesInfoList: [],
      picList: [],
      storyText: '',
      storyRole: '',
      wordCount: 0,
      writtingNode: '',
      showFirstSave: false,
      saveLoading: false,
      showPublishModal: false,
      isPublish: false,
      contentUpdateData: {},
      contentCompEditData: {},
      saveParams: {
        noteDetail: '',
        noteTitle: '',
        name: '',
      },
      STORY_TYPE_MAP,
      TOPIC_LIST,
      copyWritingContentId: undefined,
      storyList: [
        // {
        //   timeStr: '1694509500914',
        //   loading: false,
        //   hasRate: false,
        //   title: '',
        //   editWordCount: 0,
        //   postParams: {
        //     storyWay: 'MODEL',
        //     storyType: 'kol',
        //     customer: '城市白领',
        //     lifestyle: '每天奔波于婚礼现场和场地布置中',
        //     occupation: '婚礼策划师',
        //     wordCount: 150,
        //     features: [
        //       {
        //         thdFeature: '内饰',
        //       },
        //       {
        //         thdFeature: '真皮座椅',
        //       },
        //       {
        //         thdFeature: '灯光',
        //       },
        //     ],
        //     principalId: '1164026955345883136',
        //     principalName: '上汽奥迪',
        //     seriesId: '5998',
        //     seriesName: '奥迪A7L',
        //     setting: '每天奔波各种现场，搭建婚礼场地，会遇到各种突发情况要解决，买台代步车更方便工作',
        //   },
        //   makeStoryParams: {
        //     ctime: '2023-09-12 17:06:21.226859',
        //     role: '一',
        //     story:
        //       '作为一名婚礼策划师，每天奔波于大城市的林立高楼中，经常需要搭建婚礼场地，会遇到各种突发情况要解决。这时，一台能够承载生活与工作疲惫感的座驾显得尤为重要。幸好，我选择了上汽奥迪的奥迪A7L汽车。这款车的内饰功能让我感到十分满意。车内所有手触碰到的地方都是软材质，大量的真皮包裹，尤其细腻。有一次，为了搭建婚礼现场，需要将大量的装饰品和鲜花运送到现场。但是，路上突然下起了大雨，装饰品沾湿了水分，鲜花也变得不太新鲜。好在车内的真皮座椅和地毯可以迅速将多余的水分吸干，避免了更大的损失。而且，这款车的菱形格纹路的座椅足以显示出座椅的豪华规格。有一次，我需要前往商务会议，时间很紧，但路上堵车严重。如果不是车内的真皮座椅让我感觉很舒适，我可能会因为身体不适而无法专注于会议。如果你也像我一样，需要一台能够承载生活与工作疲惫感的座驾，那么这款车是你最好的选择。它的内饰和真皮座椅功能能够满足你的需求，让你在忙碌的工作中也能感受到舒适和豪华。',
        //     setting: '每天奔波各种现场，搭建婚礼场地，会遇到各种突发情况要解决，买台代步车更方便工作',
        //     plan: '计划：\n\n首先写一个情景：作为一名婚礼策划师，每天奔波于大城市的林立高楼中，经常需要搭建婚礼场地，会遇到各种突发情况要解决。这时，一台能够承载生活与工作疲惫感的座驾显得尤为重要。\n\n然后介绍第一个功能：内饰。在这样的情况下，我选择了这款车的内饰功能。车内但凡手触碰到的地方都是软材质，大量的真皮包裹，尤其细腻。我记得有一次，为了搭建婚礼现场，需要将大量的装饰品和鲜花运送到现场。但是，路上突然下起了大雨，装饰品沾湿了水分，鲜花也变得不太新鲜。好在车内的真皮座椅和地毯可以迅速将多余的水分吸干，避免了更大的损失。\n\n接着介绍第二个功能：真皮座椅。这款车的菱形格纹路的座椅足以显示出座椅的豪华规格。有一次，我需要前往商务会议，时间很紧，但路上堵车严重。如果不是车内的真皮座椅让我感觉很舒适，我可能会因为身体不适而无法专注于会议。\n\n最后，呼吁读者采取行动。如果你也像我一样，需要一台能够承载生活与工作疲惫感的座驾，那么这款车是你最好的选择。它的内饰和真皮座椅功能能够满足你的需求，让你在忙碌的工作中也能感受到舒适和豪华。',
        //     features: [
        //       {
        //         thdFeature: '内饰',
        //         featureDesc:
        //           '身为婚礼策划师，每天奔波于大城市的林立高楼中\n所以一辆能承载生活与工作疲惫感的座驾显得尤为重要',
        //       },
        //       {
        //         thdFeature: '真皮座椅',
        //         featureDesc:
        //           '车内但凡手触碰到的地方都是软材质，大量的真皮包裹，尤其细腻。菱形格纹路的座椅足以显示出座椅的豪华规格。',
        //       },
        //     ],
        //   },
        //   aiStory:
        //     '标题：奥迪A7L：承载生活与工作的豪华座驾\n\n作为一名婚礼策划师，每天都要应对各种突发情况，工作繁忙，身心俱疲。\n\n在选择座驾时，必须考虑到生活和工作的需求，所以我选择了上汽奥迪的奥迪A7L。\n\n这款车内饰华丽，处处是软材质，镶嵌着大量真皮，美轮美奂。\n\n有一次，我需要将大量装饰品和鲜花运送到婚礼现场，路上突然下起了大雨，装饰品沾湿了水分，鲜花也变得不太新鲜。\n\n但是，车内的真皮座椅和地毯可以迅速将多余的水分吸干，避免了更大的损失。\n\n这款车的菱形格纹路的座椅十分豪华，每次出行都让我沉浸在私人空间的奢华氛围中。\n\n有一次，我需要前往商务会议，时间紧迫，但路上堵车严重。\n\n如果不是车内的真皮座椅让我感觉很舒适，我可能会因为身体不适而无法专注于会议。\n\n简单来说，奥迪A7L每次的出行都让我感受到舒适和豪华。\n\n它的内饰和真皮座椅功能能够满足我的需要，让我在忙碌的工作中也能感受到生活的美好。\n\n如果你也需要一款能够承载生活和工作的座驾，奥迪A7L是绝了！',
        //   showTip: false,
        //   isDone: true,
        //   searchTagList: [],
        //   showEdit: false,
        //   tagList: [],
        //   hashTagLoading: false,
        //   showHashTag: false,
        //   eventsource: {},
        //   tagListBeforeEdit: [], // 编辑前的话题
        //   showStop: true,
        //   saveId: null,
        //   readTitle: '',
        // },
      ],
      editorList: [],
      showDrawer: false,
      showStoryData: {
        postParams: {},
        makeStoryParams: {},
      },
      showPublishDrawer: false,
      drawerEditData: {},
      // source: null,
      currentTab: 1,
    };
  },
  mounted() {
    this.fetchPrincipalList();
    this.getAigcTagList();
    this.getRewriteTypeList();
    if (this.$route.query.noteId) {
      this.handleTags(3);
      this.$refs.ContentComp.sourceNoteLink = this.$route.query.noteId;
    }
  },
  methods: {
    handleAddCancel() {
      this.$refs.addForm1.clearValidate();
      this.addFormState.principalId = '1164026955345883136';
      this.addFormState.vehicleSeriesId = undefined;
      this.getSeriesList('1164026955345883136', 'addSeriesList');
      this.showAddModal = false;
    },
    handleAddOk() {
      this.$refs.addForm1.validate(async (valid) => {
        if (valid) {
          if (this.myType == 'save') {
            if (this.storyIndex.loading) return;
            const validate = this.validateSaveParams(this.storyIndex);
            if (validate) {
              this.showAddModal = false;
              (this.saveParams.carSeriesId = this.addFormState.vehicleSeriesId),
                (this.saveParams.carSeriesName = this.addSeriesList.find(
                  (val) => val.id === this.addFormState.vehicleSeriesId
                ).name),
                (this.saveParams.principalId = this.addFormState.principalId),
                (this.saveParams.principalName = this.principalList.find(
                  (val) => val.value === this.addFormState.principalId
                ).label);
              this.saveParams.setting = null;
              if (this.saveParams.saveId) {
                await this.handleSaveRequest((data) => {
                  this.showFirstSave = false;
                  this.$message.success('保存成功');
                  let value = this.storyList.find((val) => val.timeStr === this.saveParams.timeStr);
                  value.saveId = data;
                  console.log(value, this.storyList, '-----saveSuccess');
                }).finally(() => (this.saveLoading = false));
              } else {
                this.showFirstSave = true;
              }
            }
          } else if (this.myType == 'publish') {
            if (!this.storyIndex.aiStory) {
              this.$message.error('缺少文案');
              return false;
            }
            this.showAddModal = false;
            // const principalNameAndVehicleSeriesName = {
            //   principalId: this.addFormState.principalId,
            //   principalName: this.principalList.find((val) => val.value === this.addFormState.principalId).label,
            //   vehicleSeriesId: this.addFormState.vehicleSeriesId,
            //   vehicleSeriesName: this.addSeriesList.find((val) => val.id === this.addFormState.vehicleSeriesId).name,
            // };
            if (this.storyIndex.saveId) {
              const validate = this.validateSaveParams(this.storyIndex);
              if (validate) {
                (this.saveParams.carSeriesId = this.addFormState.vehicleSeriesId),
                  (this.saveParams.carSeriesName = this.addSeriesList.find(
                    (val) => val.id === this.addFormState.vehicleSeriesId
                  ).name),
                  (this.saveParams.principalId = this.addFormState.principalId),
                  (this.saveParams.principalName = this.principalList.find(
                    (val) => val.value === this.addFormState.principalId
                  ).label);
                this.saveParams.setting = null;
                const hide = this.$message.loading('请稍后...', 0);
                await this.handleSaveRequest(() => {
                  const { href } = this.$router.resolve({
                    name: 'copywritingDetail',
                    query: {
                      id: this.storyIndex.saveId,
                      principalId: this.addFormState.principalId,
                      principalName: this.principalList.find((val) => val.value === this.addFormState.principalId)
                        .label,
                      vehicleSeriesId: this.addFormState.vehicleSeriesId,
                      vehicleSeriesName: this.addSeriesList.find((val) => val.id === this.addFormState.vehicleSeriesId)
                        .name,
                    },
                  });
                  window.open(href, '_blank');
                }).finally(() => hide());
              }
            } else {
              if (!this.storyIndex.aiStory) {
                this.$message.error('缺少文案');
                return false;
              }
              const itemName = `copy_${this.storyIndex.timeStr}`;
              const content = JSON.stringify(cloneDeep(this.storyIndex));
              localStorage.setItem(itemName, content);
              const { href } = this.$router.resolve({
                name: 'copywritingDetail',
                query: {
                  timeStr: this.storyIndex.timeStr,
                  principalId: this.addFormState.principalId,
                  principalName: this.principalList.find((val) => val.value === this.addFormState.principalId).label,
                  vehicleSeriesId: this.addFormState.vehicleSeriesId,
                  vehicleSeriesName: this.addSeriesList.find((val) => val.id === this.addFormState.vehicleSeriesId)
                    .name,
                },
              });
              window.open(href, '_blank');
            }
          }
        } else {
          return false;
        }
      });
    },
    // 处理选填方向
    handleTags(value) {
      const trackList = ['marketing', 'practical_info', 'customize'];
      this.handleTrack('toggle_ai_text_type', {
        type: trackList[value - 1],
      });
      this.currentTab = value;
      this.form1.topic = value;
      TOPIC_LIST.forEach((item) => {
        if (item.value === this.form1.topic) {
          item.isChoose = true;
        } else {
          item.isChoose = false;
        }
      });
    },
    flattenTree(tree) {
      const result = [];
      for (const item of tree) {
        traverse(item);
      }
      function traverse(node) {
        result.push(node);
        if (node.child) {
          for (const c of node.child) {
            traverse(c);
          }
        }
      }
      return result;
    },
    // 获取干货标签
    async getAigcTagList() {
      let { data } = await api.getAigcTagListApi();
      this.tagInfoList = data;
      this.tagInfoList.forEach((item) => {
        this.$set(item, 'isChoose', false);
      });
      let list = data.map((item) => {
        return item.child;
      });
      this.tagInfoChildList = list.flat();
      this.tagInfoChildList.forEach((item) => {
        this.$set(item, 'isChoose', false);
      });
      // console.log(list.flat(),'2')
      // console.log(this.flattenTree(list), '22222');
    },
    // 改写类型
    async getRewriteTypeList() {
      this.handleRequest('getToolTypeList', (data) => {
        this.rewriteTypeList = data;
        if (data.length && this.$refs.ContentComp) this.$refs.ContentComp.rewriteType = data[0].id;
      });
    },
    // // 故事视角
    // async getAigcDictStoryType() {
    //   const params = {
    //     creator: 'administrator',
    //     group: 'story_type',
    //     page: 1,
    //     pageSize: 99,
    //   };
    //   await this.handleRequest(
    //     'getAigcDictsList',
    //     (data) => {
    //       this.storyTypeList = data.list.map((item) => {
    //         return {
    //           label: STORY_TYPE_MAP[item.name],
    //           value: item.name,
    //         };
    //       });
    //     },
    //     params
    //   );
    // },
    scrollToBottom() {
      const item = this.$refs.article;
      utils.scrollBottom(item);
    },
    // ------------------------------------------------------------ 编辑文案相关 start
    async handleSave(storyData, type) {
      // if (!storyData.postParams.storyType) {
      //   this.storyIndex = storyData;
      //   this.myType = type;
      //   this.showAddModal = true;
      //   this.addFormState.principalId = '1164026955345883136';
      //   this.addFormState.vehicleSeriesId = undefined;
      //   this.getSeriesList('1164026955345883136', 'addSeriesList');
      // } else {
      //   if (storyData.loading) return;
      //   const validate = this.validateSaveParams(storyData);
      //   if (validate) {
      //     if (this.saveParams.saveId) {
      //       await this.handleSaveRequest((data) => {
      //         this.showFirstSave = false;
      //         this.$message.success('保存成功');
      //         let value = this.storyList.find((val) => val.timeStr === this.saveParams.timeStr);
      //         value.saveId = data;
      //         console.log(value, this.storyList, '-----saveSuccess');
      //       }).finally(() => (this.saveLoading = false));
      //     } else {
      //       this.showFirstSave = true;
      //     }
      //   }
      // }
      // v6版本 - 无论什么选题方向 保存逻辑都改为一致
      if (storyData.loading) return;
      this.handleTrack(type === 'save' ? 'save_text' : 'publish_text');
      handleNoteCheck(storyData.aiStory, { showMsg: true }).then(({ newNote, oldNote }) => {
        if (newNote !== oldNote) {
          storyData.isNoteCheckError = true;
        } else {
          storyData.isNoteCheckError = false;
        }
        const validate = this.validateSaveParams(storyData);
        if (validate) {
          this.handleSaveRequest((data) => {
            this.storyList.forEach((val) => {
              if (val.timeStr === this.saveParams.timeStr) {
                val.saveId = data;
              }
              // v8版本 发布先保存再跳转详情页
              if (type == 'publish') {
                let routeData = this.$router.resolve({
                  name: 'articleDetail',
                  query: { draftId: data },
                });
                window.open(routeData.href, '_blank');
              }
              if (type == 'save') {
                const link = this.$router.resolve({
                  name: 'copywritingList',
                });
                const messageComp = this.$createElement(MessageTextCompVue, {
                  props: { message: '已保存草稿，查看笔记可前往', pageName: '草稿箱', link: link.href },
                });
                this.$message.success({
                  content: messageComp,
                  key: 'darf',
                  duration: 3,
                });
              }
            });
          }).finally(() => (this.saveLoading = false));
        }
      });
    },
    async handleSaveOk() {
      if (!this.saveParams.name) this.saveParams.name = this.saveParams.noteDetail.slice(0, 15);
      this.saveLoading = true;
      console.log(this.saveParams, '保存参数');
      await this.handleSaveRequest((data) => {
        console.log(data, '---saveID');
        this.showFirstSave = false;
        this.$message.success('保存成功');
        let value = this.storyList.find((val) => val.timeStr === this.saveParams.timeStr);
        value.saveId = data;
      }).finally(() => (this.saveLoading = false));
    },
    validateSaveParams(storyData) {
      if (!storyData.aiStory) {
        this.$message.error('缺少文案');
        return false;
      }
      // if (!storyData.title) {
      //   this.$message.error('缺少标题');
      //   return false;
      // }

      this.saveParams = {
        saveId: storyData.saveId || null,
        timeStr: storyData.timeStr,
        // 非种草类型品牌车系为非必填
        carSeriesId: storyData.postParams.storyType ? storyData.postParams.seriesId : null,
        carSeriesName: storyData.postParams.storyType ? storyData.postParams.seriesName : null,
        principalId: storyData.postParams.storyType ? storyData.postParams.principalId : null,
        principalName: storyData.postParams.storyType ? storyData.postParams.principalName : null,
        // ----------------------------- 内容需求
        storyType: storyData.postParams.storyType,
        wordCount: storyData.postParams.wordCount,
        storyWay: storyData.postParams.storyWay,
        // ----------------------------- 人设
        customer: storyData.makeStoryParams.customer,
        occupation: storyData.makeStoryParams.occupation,
        lifestyle: storyData.makeStoryParams.lifestyle,
        setting: storyData.postParams.storyType ? storyData.makeStoryParams.setting : null, // 非种草类型setting传null
        featureArr: storyData.makeStoryParams.features?.map((val) => ({ thdFeature: val.thdFeature })) || [],
        // ----------------------------- 故事
        role: storyData.makeStoryParams.role,
        story: storyData.makeStoryParams.story,
        // ----------------------------- 笔记文案
        noteTitle: storyData.title,
        noteDetail: storyData.aiStory,
        noteDetailHtml: storyData.aiStory,
        xhsTopicList: storyData.tagList,
        name: storyData.name ? storyData.name : storyData.aiStory.slice(0, 15),
      };
      console.log(this.saveParams, '--saveParams');
      return true;
    },
    getStory(storyData) {
      this.contentUpdateData = storyData;
      const timeStr = Date.now().toString();
      this.postStory(storyData, timeStr);
    },
    async getDiyStory(params) {
      const timeStr = Date.now().toString();
      const storyItem = {
        timeStr, // 时间戳
        title: '', // 标题
        postParams: {}, // 请求生成故事参数
        makeStoryParams: {}, // 接口返回故事参数
        aiStory: '', // ai生成故事
        storyBeforeEdit: '', // 编辑前的故事
        storyAfterEdit: '', // 编辑后的故事
        editWordCount: 0, // 编辑字数
        titleBeforeEdit: '', // 编辑前的标题
        tagListBeforeEdit: [], // 编辑前的话题
        showTip: false, // 空话题提示
        isDone: false, // 是否完成
        searchTagList: [], // 搜索话题列表
        newHashTag: undefined, // 手动话题
        showEdit: false, // 编辑状态
        tagList: [], // 全部话题列表
        hashTagLoading: false,
        showHashTag: false,
        loading: false, // ai loading
        hasRate: false, // 是否已评分
        saveId: null, // 是否已保存
        eventsource: null,
        showStop: false, // 是否显示停止按钮
        showError: false, // 是否出错
        errorMsg: '', // 报错msg
        readTitle: '', // 只读标题
        isNoteCheckError: false, // 敏感词检测
        showCheckTag: false, // AI标签
        indeterminate: false, // 标签半选状态
        checkAllTag: false, // 标签全选状态
        tagAllList: [], // ai标签列表
        tagCheckedList: [], // ai标签已选列表
        tagOptionList: [], // ai标签展示
        showAiTitle: false, // AI标题
        titleByAi: null, // 选择AI标题
        aiTitleList: null, // AI标题列表
      };

      this.storyList.push(storyItem);

      this.$nextTick(() => {
        this.scrollToBottom();
      });

      let value = this.storyList.find((val) => val.timeStr === timeStr);
      const { data, code, message } = await api['getAiRewriteStory'](params);
      if (code === 200) {
        handleNoteCheck(data.rewriteContent)
          .then(({ newNote, oldNote }) => {
            value.aiStory = newNote;
            if (newNote !== oldNote) {
              value.isNoteCheckError = true;
            } else {
              value.isNoteCheckError = false;
            }
          })
          .catch(() => {
            value.aiStory = data.rewriteContent;
            value.isNoteCheckError = false;
          })
          .finally(() => {
            value.makeStoryParams = this.getSourceData(data);
            value.isDone = true;
          });
      } else {
        value.isDone = true;
        value.showError = true;
        value.hasRate = true;
        value.errorMsg = message;
        this.$message.error(message);
      }
    },
    async postStory(postParams, timeStr) {
      const storyItem = {
        timeStr, // 时间戳
        title: '', // 标题
        postParams, // 请求生成故事参数
        makeStoryParams: {}, // 接口返回故事参数
        aiStory: '', // ai生成故事
        storyBeforeEdit: '', // 编辑前的故事
        storyAfterEdit: '', // 编辑后的故事
        editWordCount: 0, // 编辑字数
        titleBeforeEdit: '', // 编辑前的标题
        tagListBeforeEdit: [], // 编辑前的话题
        showTip: false, // 空话题提示
        isDone: false, // 是否完成
        searchTagList: [], // 搜索话题列表
        newHashTag: undefined, // 手动话题
        showEdit: false, // 编辑状态
        tagList: [], // 全部话题列表
        hashTagLoading: false,
        showHashTag: false,
        loading: false, // ai loading
        hasRate: false, // 是否已评分
        saveId: null, // 是否已保存
        eventsource: null,
        showStop: false, // 是否显示停止按钮
        showError: false, // 是否出错
        errorMsg: '', // 报错msg
        readTitle: '', // 只读标题
        isNoteCheckError: false, // 敏感词检测
        showCheckTag: false, // AI标签
        indeterminate: false, // 标签半选状态
        checkAllTag: false, // 标签全选状态
        tagAllList: [], // ai标签列表
        tagCheckedList: [], // ai标签已选列表
        tagOptionList: [], // ai标签展示
        showAiTitle: false, // AI标题
        titleByAi: null, // 选择AI标题
        aiTitleList: null, // AI标题列表
      };
      // const source = axios.CancelToken.source();
      // storyItem.source = source;
      this.storyList.push(storyItem);

      this.$nextTick(() => {
        this.scrollToBottom();
      });

      let value = this.storyList.find((val) => val.timeStr === timeStr);
      if (this.form1.topic == 1) {
        const API = postParams.storyWay === 'MODEL' ? 'handleGetCopywriting' : 'handleGetFreeCopywriting';
        const { data, code, message } = await api[API](postParams);
        if (code === 200) {
          handleNoteCheck(data.note)
            .then(({ newNote, oldNote }) => {
              value.aiStory = newNote;
              if (newNote !== oldNote) {
                value.isNoteCheckError = true;
              } else {
                value.isNoteCheckError = false;
              }
            })
            .catch(() => {
              value.aiStory = data.note;
              value.isNoteCheckError = false;
            })
            .finally(() => {
              value.makeStoryParams = this.getSourceData(data);
              value.isDone = true;
            });
        } else {
          if (message == 'stopMakeStory') {
            let index = this.storyList.findIndex((val) => val.timeStr === timeStr);
            this.storyList.splice(index, 1);
            this.$message.info('已停止请求');
          } else {
            value.isDone = true;
            value.showError = true;
            value.hasRate = true;
            value.errorMsg = message;
            this.$message.error(message);
          }
        }
      } else if (this.form1.topic == 2) {
        const { data, code, message } = await api['getXhsSubstanceNoteApi'](postParams);
        if (code === 200) {
          handleNoteCheck(data.note)
            .then(({ newNote, oldNote }) => {
              value.aiStory = newNote;
              if (newNote !== oldNote) {
                value.isNoteCheckError = true;
              } else {
                value.isNoteCheckError = false;
              }
            })
            .catch(() => {
              value.aiStory = data.note;
              this.isNoteCheckError = false;
            })
            .finally(() => {
              value.makeStoryParams = this.getSourceData(data);
              value.isDone = true;
            });
        } else {
          if (message == 'stopMakeStory') {
            let index = this.storyList.findIndex((val) => val.timeStr === timeStr);
            this.storyList.splice(index, 1);
            this.$message.info('已停止请求');
          } else {
            value.isDone = true;
            value.showError = true;
            value.hasRate = true;
            value.errorMsg = message;
            this.$message.error(message);
          }
        }
      }
    },
    handleDel(timeStr) {
      let index = this.storyList.findIndex((val) => val.timeStr === timeStr);
      this.storyList.splice(index, 1);
    },
    getSourceData(data) {
      const reg = new RegExp('\r\n', 'g');
      const returnData = {
        role: data.role,
        story: data.story ? data.story.replace(reg, '<br >') : '',
        plan: data.plan ? data.plan.replace(reg, '<br >') : '',
        occupation: data.occupation,
        lifestyle: data.lifestyle,
        customer: data.customer,
        setting: this.form1.topic == 1 && data.setting ? data.setting.replace(reg, '<br >') : data.setting,
        features:
          data.features?.map((val) => ({
            feature_code: val.featureCode,
            thdFeature: val.thdFeature,
            featureDesc: val.featureDesc.replace(reg, '<br >'),
          })) || [],
        featuresView:
          data.features
            ?.map((val) => ({
              feature_code: val.featureCode,
              thdFeature: val.thdFeature,
              featureDesc: val.featureDesc.replace(reg, '<br >'),
            }))
            .splice(0, 2) || [],
      };
      console.log(returnData, '-------------returnData');
      return returnData;
    },
    getInnerText: function (t) {
      // 会读取到按钮文本 废弃
      if (!t) return '';
      var e = null == t ? void 0 : t.innerHTML;
      e = e
        .replace(/<img([^>]+)data-set="x-x-/g, '')
        .replace(/-x-x([^>]*)>/g, '')
        .replace(/<br>/g, '<硬换行>')
        .replace(/<(\w+)([^>]*)class="data-clipboard-text-hide"([^>]*)>#<\/\1>/gi, '#')
        .replace(/<(\w+)(\s)class="content-hide([^>]*)>(.*?)<\/\1>/gi, '[话题]#')
        .replace(/<(\w+)([^>]*)class="data-clipboard-text-hide"([^>]*)>\[时刻\]#<\/\1>/gi, '[时刻]#');
      var n = document.createElement('div');
      (n.innerHTML = e), document.body.append(n);
      var r = n.innerText.replace(/<硬换行>/g, '\t\n');
      return document.body.removeChild(n), r;
    },
    getHtmlText(innerHTML) {
      const text = innerHTML
        .replace(/<img([^>]+)data-set="x-x-/g, '')
        .replace(/-x-x([^>]*)>/g, '')
        .replace(/<br>/g, '<硬换行>')
        .replace(/`<br>`/g, '<硬换行>')
        .replace(/<(\w+)([^>]*)class="data-clipboard-text-hide"([^>]*)>#<\/\1>/gi, '#')
        .replace(/<(\w+)(\s)class="content-hide([^>]*)>(.*?)<\/\1>/gi, '[话题]#')
        .replace(/<(\w+)([^>]*)class="data-clipboard-text-hide"([^>]*)>\[时刻\]#<\/\1>/gi, '[时刻]#');
      var n = document.createElement('div');
      (n.innerHTML = text), document.body.append(n);
      var r = n.innerText.replace(/<硬换行>/g, '\n');
      return document.body.removeChild(n), r;
    },
    initEditor(timeStr, aiStory) {
      const _this = this;
      let editor = null;
      editor = new E(`#editorElem${timeStr}`);
      editor.config.focus = false;
      editor.config.onchange = (newHtml) => {
        let value = _this.storyList.find((val) => val.timeStr === timeStr);
        let text = _this.getHtmlText(newHtml);
        value.storyAfterEdit = text;
        value.editWordCount = editor.txt.text().replace(/&nbsp;/gi, ' ').length;
      };
      editor.config.zIndex = 1;
      editor.config.emotions = [];
      editor.config.showFullScreen = false;
      editor.config.menus = ['emoticon', 'undo', 'redo'];
      // 初始化自定义表情
      EMOJI_LIST.forEach((item) => {
        const { title, type, content } = item;
        editor.config.emotions.push({
          title,
          type,
          content: Array.from(content),
        });
      });

      this.editorList.push({
        ele: editor,
        timeStr,
      });

      this.$nextTick(() => {
        editor.create();
        setTimeout(() => {
          editor.txt.html(aiStory.replace(/\n/g, '<br />'));
        }, 200);
      });
    },
    handleStoryEdit(storyData) {
      this.handleTrack('edit_text');

      storyData.showCheckTag = false;
      storyData.showAiTitle = false;
      storyData.hasRate = true;
      if (storyData.loading) return;
      storyData.showEdit = true;
      storyData.storyBeforeEdit = storyData.aiStory;
      storyData.storyAfterEdit = '';
      storyData.editWordCount = storyData.aiStory.length;
      storyData.titleBeforeEdit = storyData.title;
      storyData.tagListBeforeEdit = cloneDeep(storyData.tagList);
      this.initEditor(storyData.timeStr, storyData.aiStory);
    },
    async handleEditConfirm(data) {
      handleNoteCheck(data.storyAfterEdit)
        .then(({ newNote, oldNote }) => {
          data.aiStory = newNote;
          if (newNote !== oldNote) {
            data.isNoteCheckError = true;
          } else {
            data.isNoteCheckError = false;
          }
        })
        .catch(() => {
          data.aiStory = data.storyAfterEdit;
          data.isNoteCheckError = false;
        })
        .finally(() => {
          const reg = new RegExp('\r\n', 'g');
          data.readTitle = data.title.replace(reg, '<br >');
          data.storyAfterEdit = '';
          data.storyBeforeEdit = '';
          data.editWordCount = 0;
          data.tagListBeforeEdit = [];
          setTimeout(() => {
            const index = this.editorList.findIndex((val) => val.timeStr === data.timeStr);
            let editor = this.editorList[index].ele;
            if (!editor) return;
            editor && editor.destroy();
            editor = null;
            this.editorList.splice(index, 1);
            data.showEdit = false;
          }, 300);
        });
    },
    handleEditClose(data) {
      data.aiStory = data.storyBeforeEdit
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<[^>]+>/gi, '')
        .replace(/&nbsp;/gi, ' ');
      data.storyAfterEdit = '';
      data.storyBeforeEdit = '';
      data.editWordCount = 0;
      data.title = data.titleBeforeEdit;
      data.titleBeforeEdit = '';
      data.tagList = cloneDeep(data.tagListBeforeEdit);
      data.tagListBeforeEdit = [];

      setTimeout(() => {
        const index = this.editorList.findIndex((val) => val.timeStr === data.timeStr);
        let editor = this.editorList[index].ele;
        if (!editor) return;
        editor && editor.destroy();
        editor = null;
        this.editorList.splice(index, 1);
        data.showEdit = false;
      }, 300);
    },
    // async handleStop(storyData) {
    // let value = this.storyList.find((val) => val.timeStr === storyData.timeStr);
    // value.source.cancel('stopMakeStory');
    // },
    addEmoji(storyData) {
      storyData.showCheckTag = false;
      storyData.showAiTitle = false;
      storyData.hasRate = true;
      if (storyData.loading) return;
      this.handleTrack('click_face');
      storyData.loading = true;
      this.handleRequest(
        'getEmojiContent',
        (data) => {
          storyData.aiStory = data.note;
        },
        {
          note: storyData.aiStory,
        }
      ).finally(() => {
        storyData.loading = false;
      });
    },
    addTitle(storyData) {
      if (storyData.loading) return;
      this.handleTrack('click_title');

      storyData.loading = true;
      this.handleRequest(
        'getContentTitle',
        (data) => {
          if (data.length) {
            storyData.aiTitleList = data;
            storyData.showAiTitle = true;
          } else {
            this.$message.info('请重试');
          }
        },
        {
          note: storyData.aiStory,
        }
      ).finally(() => {
        storyData.loading = false;
      });
    },
    // ------------------------------------------------------------ 编辑文案相关 end

    // ------------------------------------------------------------ hashtag相关 start
    addHashTag(storyData) {
      storyData.showAiTitle = false;
      if (storyData.loading) return;
      this.handleTrack('click_tags');
      if (!storyData.title) return this.$message.info('标题不可为空');
      storyData.loading = true;
      this.handleRequest(
        'getHashTagContent',
        (data) => {
          storyData.tagAllList = data;
          storyData.tagCheckedList = data.filter((val) => val.isHot).map((val) => val.id);
          if (storyData.tagCheckedList.length > 0 && storyData.tagCheckedList.length < data.length) {
            storyData.indeterminate = true;
            storyData.checkAllTag = false;
          }
          if (storyData.tagCheckedList.length == data.length) {
            storyData.indeterminate = false;
            storyData.checkAllTag = true;
          }
          storyData.tagOptionList = data.map((val) => {
            return {
              value: val.id,
              label: val.isHot ? `🔥${val.name}` : val.name,
            };
          });
          storyData.showCheckTag = true;
          // const oldList = storyData.tagList.map((val) => val.id);
          // const newList = data.filter((val) => oldList.indexOf(val.id) === -1);
          // storyData.tagList.push(...newList);
        },
        {
          noteDetail: storyData.aiStory,
          noteTitle: storyData.title,
        }
      ).finally(() => {
        storyData.loading = false;
      });
    },
    onTagChange(tagCheckedList, storyData) {
      storyData.indeterminate = !!tagCheckedList.length && tagCheckedList.length < storyData.tagOptionList.length;
      storyData.checkAllTag = tagCheckedList.length === storyData.tagOptionList.length;
    },
    onCheckAllTag(e, storyData) {
      Object.assign(storyData, {
        tagCheckedList: e.target.checked ? storyData.tagOptionList.map((val) => val.value) : [],
        indeterminate: false,
        checkAllTag: e.target.checked,
      });
    },
    confirmAddTag(storyData) {
      const oldList = storyData.tagList.map((val) => val.id);
      let chooseList = [];
      storyData.tagAllList.forEach((item) => {
        storyData.tagCheckedList.forEach((value) => {
          if (item.id == value) {
            chooseList.push(item);
          }
        });
      });
      const newList = chooseList.filter((val) => oldList.indexOf(val.id) === -1);
      storyData.tagList.push(...newList);
      this.handleTagClose(storyData);
    },
    handleTagClose(storyData) {
      storyData.tagAllList = [];
      storyData.tagCheckedList = [];
      storyData.tagOptionList = [];
      storyData.indeterminate = false;
      storyData.checkAllTag = false;
      storyData.showCheckTag = false;
    },
    confirmTitle(storyData) {
      if (storyData.titleByAi?.trim()) {
        storyData.title = storyData.titleByAi;
        storyData.readTitle = storyData.titleByAi;
      }
      this.handleTitleClose(storyData);
    },
    handleTitleClose(storyData) {
      storyData.titleByAi = null;
      storyData.aiTitleList = [];
      storyData.showAiTitle = false;
    },
    automaticLineWrapping(storyData) {
      storyData.showCheckTag = false;
      storyData.showAiTitle = false;
      storyData.hasRate = true;
      if (storyData.loading) return;
      this.handleTrack('click_line_feed');

      storyData.loading = true;
      this.handleRequest(
        'automaticLineWrapping',
        (data) => {
          storyData.aiStory = data.note;
        },
        {
          note: storyData.aiStory,
        }
      ).finally(() => {
        storyData.loading = false;
      });
    },
    handleAddTagClick(data) {
      data.showHashTag = true;
    },
    handleClose(id, data) {
      const list = cloneDeep(data.tagList);
      const tags = list.filter((tag) => tag.id !== id);
      data.tagList = tags;
    },
    handleHashTagSearch(value, storyData) {
      if (value) {
        if (!storyData.title) return this.$message.error('请输入文案标题');
        if (!storyData.aiStory) return this.$message.error('请输入文案内容');
        const params = {
          noteDetail: storyData.aiStory,
          noteTitle: storyData.title,
          keyword: value,
        };
        storyData.searchTagList = [];
        storyData.showTip = false;
        storyData.hashTagLoading = true;
        this.handleRequest(
          'getHashTagSearch',
          (data) => {
            if (data.length) {
              storyData.searchTagList = data;
            } else {
              storyData.showTip = true;
            }
          },
          params
        ).finally(() => (storyData.hashTagLoading = false));
      }
    },
    handleHashTagChange(value, storyData) {
      const item = storyData.searchTagList.find((val) => val.id === value);
      const index = storyData.tagList.findIndex((val) => val.id === value);
      if (index === -1) {
        storyData.tagList.push(item);
      } else {
        this.$message.info('该话题已存在');
      }
      storyData.newHashTag = undefined;
      storyData.showHashTag = false;
    },
    handleHashTagBlur(value, storyData) {
      storyData.showTip = false;
      storyData.showHashTag = false;
      storyData.newHashTag = undefined;
    },
    async handleRate(rate, storyData) {
      storyData.hasRate = true;
      const contentObj = JSON.stringify({
        aiStory: storyData.aiStory,
        postParams: storyData.postParams,
        makeStoryParams: storyData.makeStoryParams,
      });
      const params = {
        score: rate,
        contentObj,
      };
      const { code, message } = await api.handleAiRate(params);
      if (code === 200) {
        this.$message.success('评分成功');
      } else {
        this.$message.error(`${message}`);
        storyData.hasRate = false;
      }
    },
    // ------------------------------------------------------------ hashtag相关 end

    async fetchPrincipalList() {
      api.getBrandList().then((res) => {
        this.principalList = res.data.map((item) => {
          return { label: item.principalName, value: item.id };
        });
        // 默认选中奥迪品牌
        if (res.data.find((val) => val.id === '1164026955345883136')) {
          this.addFormState.principalId = '1164026955345883136';
          this.getSeriesList('1164026955345883136', 'addSeriesList');
        }
      });
    },
    async handleAddPrincipalChange(id) {
      this.addFormState.vehicleSeriesId = undefined;
      this.addFormState.vehicleSeriesId = undefined;
      let principalIds = id ? id : '';
      if (!id) this.addSeriesList = [];
      principalIds && (await this.getSeriesList(principalIds, 'addSeriesList'));
    },
    getSeriesList(principalIds, typeList) {
      this[typeList] = [];
      if (!principalIds) return;
      this.seriesLoading = true;
      api
        .getTypeList({ principalIds })
        .then((res) => {
          this[typeList] = res.data.map((val) => ({ id: val.id, name: val.name }));
        })
        .finally(() => (this.seriesLoading = false));
    },
    seriesChange() {
      this.principalInfoList = [
        {
          principalId: this.addFormState.principalId,
          principalName: this.principalList.find((val) => val.value === this.addFormState.principalId).label,
        },
      ];
      this.vehicleSeriesInfoList = [
        {
          vehicleSeriesId: this.addFormState.vehicleSeriesId,
          vehicleSeriesName: this.addSeriesList.find((val) => val.id === this.addFormState.vehicleSeriesId).name,
        },
      ];
      setTimeout(() => {
        this.initComponent();
      }, 200);
    },
    initComponent() {
      this.$refs.ContentComp && this.$refs.ContentComp.initContent();
    },
    updateStoryText(text) {
      this.storyText = text;
    },
    getNewNote(writtingNode) {
      this.writtingNode = writtingNode.note;
      this.$refs.NoteComp && this.$refs.NoteComp.editor.txt.html(writtingNode.note.replace(/\n/g, '<br />'));
    },
    async handlePublish(storyData, type) {
      // v6 - 发布逻辑改为统一
      if (storyData.loading) return;
      this.showPublishDrawer = true;
      this.drawerEditData = storyData;
      // const validate = this.validateSaveParams(storyData);
      // if (validate) {
      //   // 先保存 再展示发布抽屉
      //   await this.handleSaveRequest((data) => {
      //     this.$message.success('保存成功');
      //     this.storyList.forEach((val) => {
      //       if (val.timeStr === this.saveParams.timeStr) {
      //         val.saveId = data;
      //         this.showPublishDrawer = true
      //       }
      //     });
      //     console.log(value, this.storyList, '-----saveSuccess');
      //   }).finally(() => (this.saveLoading = false));
      // if (!storyData.postParams.storyType) {
      //   this.storyIndex = storyData;
      //   this.myType = type;
      //   this.showAddModal = true;
      //   this.addFormState.principalId = '1164026955345883136';
      //   this.addFormState.vehicleSeriesId = undefined;
      //   this.getSeriesList('1164026955345883136', 'addSeriesList');
      // } else {
      //   if (!storyData.aiStory) {
      //     this.$message.error('缺少文案');
      //     return false;
      //   }

      //   if (storyData.saveId) {
      //     const validate = this.validateSaveParams(storyData);
      //     if (validate) {
      //       const hide = this.$message.loading('请稍后...', 0);
      //       await this.handleSaveRequest(() => {
      //         const { href } = this.$router.resolve({
      //           name: 'copywritingDetail',
      //           query: { id: storyData.saveId },
      //         });
      //         window.open(href, '_blank');
      //       }).finally(() => hide());
      //     }
      //   } else {
      //     if (!storyData.aiStory) {
      //       this.$message.error('缺少文案');
      //       return false;
      //     }

      //     const itemName = `copy_${storyData.timeStr}`;
      //     const content = JSON.stringify(cloneDeep(storyData));
      //     localStorage.setItem(itemName, content);
      //     const { href } = this.$router.resolve({
      //       name: 'copywritingDetail',
      //       query: { timeStr: storyData.timeStr },
      //     });
      //     window.open(href, '_blank');
      //   }
      // }
    },
    async handleSaveRequest(callbackFn) {
      const id = this.saveParams.saveId ? this.saveParams.saveId : null;
      const params = cloneDeep(this.saveParams);
      delete params.saveId;
      params.id = id;
      await this.handleRequest(
        'handleSaveCopywriting',
        (data) => {
          callbackFn(data);
        },
        params
      );
    },
    handleSaveCancel() {
      this.showFirstSave = false;
      this.showPublishModal = false;
      this.isPublish = false;
    },
    handleClosePublish() {
      this.showPublishModal = false;
      this.$nextTick(() => this.$router.replace({ name: 'copywritingList' }));
    },
    // 编辑回显
    handleEdit() {
      const hide = this.$message.loading('查询中...', 0);
      this.handleRequest(
        'getCopywrittingDetail',
        (data) => {
          this.contentCompEditData = data;
          console.log(this.contentCompEditData, '--------------编辑Data');
          this.saveParams = data;
          this.principalInfoList = [
            {
              principalId: data.principalId,
              principalName: data.principalName,
            },
          ];
          this.vehicleSeriesInfoList = [
            {
              vehicleSeriesId: data.carSeriesId,
              vehicleSeriesName: data.carSeriesName,
            },
          ];
          this.picList = data.materialArr.map((val) => {
            return {
              id: Math.random(),
              showHover: false,
              fileUrl: val.previewUrl,
              fileId: val.fileId,
            };
          });

          this.picList.length && this.$refs.PicComp && this.$refs.PicComp.defineSortable();

          this.storyText = data.story;
          this.storyRole = data.role;
          this.wordCount = data.wordCount;

          this.contentUpdateData = {
            role: data.role || undefined,
            wordCount: data.wordCount || undefined,
            storyType: data.storyType || undefined,
            storyWay: data.storyWay || 'MODEL',
            customer: data.customer || undefined,
            occupation: data.occupation || undefined,
            lifestyle: data.lifestyle || undefined,
            setting: data.setting || undefined,
            features: data.featureArr?.length ? data.featureArr : [],
          };

          setTimeout(() => {
            this.$refs.ContentComp && this.$refs.ContentComp.getEditData();
            if (this.$refs.NoteComp) {
              this.$refs.NoteComp.noteTitle = data.noteTitle;
              this.$refs.NoteComp.noteContent = data.noteDetail;
              this.$refs.NoteComp.editor.txt.html(data.noteDetail.replace(/\n/g, '<br />'));
              this.$refs.NoteComp.tagList = data.xhsTopicList || [];
            }
            hide();
          }, 200);
        },
        this.$route.query.id
      ).catch(() => hide());
    },
    updatePicList(picList) {
      this.picList = [];
      this.picList = picList;
      this.picList.length && this.$refs.PicComp && this.$refs.PicComp.defineSortable();
    },
    handleOpenDrawer(storyData) {
      this.showStoryData = storyData;
      this.showDrawer = true;
      console.log(this.showStoryData, '抽屉数据');
    },
    handleTextToImg({ title, aiStory: text }) {
      this.handleTrack('build_image_by_text');
      const data = { title, text };
      api.saveImageList({ configType: 'textToImg', data }).then((res) => {
        if (res.code == 200) {
          window.open(`${process.env.VUE_APP_TEMPLATE_EDITOR}?tab=textToImg&textToImgId=${res.data}`, '_blank');
        } else {
          this.$message.error(`提交数据失败，${res.message}`);
        }
      });
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    arrayToString(val, textName) {
      let str = '';
      if (val?.length) {
        let strArr = [];
        val.forEach((value) => {
          strArr.push(value[textName]);
        });
        str = strArr.reduce((prev, item) => {
          prev += `、${item}`;
          return prev;
        });
      } else {
        str = '';
      }
      return str;
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-input-number {
  width: 100%;
}
.left {
  width: 390px;

  .left-item {
    padding: 15px;
    background-color: #fff;
    .topic {
      .topic-title {
        padding-top: 5px;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 100%;
        color: #262626;
      }

      .topic-body {
        flex: 1;
        display: flex;
        color: #2d2d2d;
        .tags {
          padding: 3px 5px;
          margin: 0 10px 10px 0;
          border-radius: 6px;
          cursor: pointer;
          border: 1px solid rgba(0, 0, 0, 0.65);
          color: rgba(0, 0, 0, 0.65);
        }
        .active {
          border: 1px solid #1890ff;
          color: #1890ff;
        }
      }
    }
  }
}

.right {
  padding: 10px 10px 10px 0;
  margin-left: 36px;
  flex: 1;
  max-height: 803px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f2f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;

    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #f0f2f5;
  }
}

.header-overflow {
  width: 95%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.story-item {
  margin-bottom: 20px;

  .item-top {
    display: flex;

    .top-left {
      position: relative;
      flex: 1;
      padding: 10px 20px;
      min-height: 40px;
      color: #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: #da89f0a4;
      cursor: pointer;
      box-shadow: 7px 8px 10px #bdb5ef47;
      border-radius: 8px 8px 0 0;
    }
    .top-right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      font-size: 23px;
    }
  }

  .item-middle {
    display: flex;

    .item-middle-left {
      flex: 1;
      min-height: 100px;
      background-color: #fff;
      border-radius: 0 0 8px 8px;
      box-shadow: 7px 8px 10px #bdb5ef47;
      z-index: 1;

      /deep/ .ant-input-affix-wrapper {
        margin: 0 !important;
      }

      .left-input {
        position: relative;
        .input-class /deep/ .ant-input:not(:last-child) {
          padding-right: 100px !important;
        }

        .only-read-title {
          width: 685px;
          padding-left: 11px;
          line-height: 30px;
          min-height: 33px;
          // line-height: 33px;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          white-space: pre-wrap;
        }

        .border-bottom {
          height: 1px;
          border-bottom: 1px rgba(0, 0, 0, 0.418) dashed;
        }

        .word-count {
          position: absolute;
          top: 5px;
          right: 30px;
          margin-left: 10px;
          color: #999999;
          z-index: 1;
        }
      }

      .only-read-text {
        padding: 15px;
        white-space: pre-wrap;

        .blink {
          &:after {
            -webkit-animation: blink 1s steps(5, start) infinite;
            animation: blink 1s steps(5, start) infinite;
            content: '▋';
            margin-left: 2px;
            vertical-align: baseline;
          }
        }
      }

      .only-read-word {
        text-align: end;
        height: 33px;
        line-height: 33px;
        color: #999999;
      }
    }

    .all-around {
      border-radius: 8px;
    }

    @keyframes blink {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
    .item-middle-right {
      position: relative;
      width: 50px;

      .right-item {
        margin-bottom: 10px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        user-select: none;

        img {
          width: 45%;
          height: 45%;
        }
      }
    }
  }

  .item-bottom {
    height: 60px;
    line-height: 60px;
  }

  .tag-line {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    color: #ababab;
  }

  .editor-style {
    /deep/ .w-e-text-container {
      min-height: 450px;
      width: 100%;
    }
  }
}

.top {
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 80px;
  border: 20px #f0f2f5 solid;
}

.bottom {
  padding: 0 25px 10px;
  background-color: #f0f2f5;
}

.bottom-item {
  padding: 18px;
  border-radius: 8px;
  background-color: #fff;
}

.text-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 45px;
}

.red {
  color: red !important;
}

.drawer-title {
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px;
}

/deep/ .ant-radio-wrapper > span:nth-child(2) {
  white-space: break-spaces;
}
</style>
