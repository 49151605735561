<template>
  <!-- 图片库弹窗 -->
  <a-modal v-model="show" :width="800" @close="handleLocalCancel" @cancel="handleLocalCancel">
    <div slot="title">
      <span>上传图片</span>
      <span class="sub-title">同步上传至图库 </span>
    </div>
    <a-upload-dragger
      name="file"
      :multiple="true"
      accept=".jpg, .png, .jpeg"
      :file-list="file_list"
      :customRequest="customRequest"
      :beforeUpload="beforeUploadImg"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">点击或将文件拖拽到这里上传</p>
      <p class="ant-upload-hint">支持扩展名：.png .jpg .jpeg</p>
    </a-upload-dragger>
    <div style="margin: 10px; color: #a8a8a8">共{{ this.file_list.length || 0 }}张图片</div>
    <div class="tag-line">
      <div class="tag-title"><span style="color: red">*</span>标签：</div>
      <div class="tag-line">
        <template v-if="show">
          <CascaderMenu
            ref="cascaderMenu"
            :option="item"
            v-for="(item, index) in tagTree"
            :key="index"
            @change="(props) => handleTagChange(props, index)"
          />
        </template>
      </div>
      <!-- <div class="tag-body">
        <div
          :class="['tags', { active: value.isChoose }]"
          v-for="value in tagList"
          :key="value.id"
          @click="handleTagClick(value.id)"
        >
          {{ value.tagName }}
        </div>
        <a-button size="small" style="margin-bottom: 10px" v-show="showAddTag" @click="showAddTag = false"
          >+添加</a-button
        >
        <div v-show="!showAddTag" class="tag-add">
          <a-input class="tag-input" v-model.trim="addTagText" placeholder="请输入标签"></a-input>
          <a-button class="tag-btn" type="primary" @click="handleTagOk" :loading="addLoading">确认</a-button>
        </div>
      </div> -->
    </div>
    <div class="bottom">
      <div style="width: 45px px; flex-shrink: 0">已选：</div>
      <div class="tag-list">
        <div class="tags" v-for="value in tagList" :key="value.id">
          <span>{{ value.parentText }}</span
          ><span style="color: #1890ff">{{ value.text }}</span>
          <span>
            <a-icon type="close" style="margin-left: 5px; cursor: pointer" @click="handleTagDel(value)" />
          </span>
        </div>
      </div>
    </div>
    <a-form-model ref="form" :model="form" v-bind="{ labelCol: { span: 3 }, wrapperCol: { span: 20 } }">
      <a-row style="margin-top: 10px">
        <a-col :span="11">
          <a-form-model-item label="品牌">
            <a-select
              mode="multiple"
              v-model="form.brand"
              placeholder="请选择"
              option-filter-prop="children"
              :maxTagCount="2"
              disabled
            >
              <a-select-option v-for="item in principalInfoList" :key="item.principalId">{{
                item.principalName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="13">
          <a-form-model-item label="车系">
            <a-select
              mode="multiple"
              v-model="form.series"
              placeholder="请选择"
              option-filter-prop="children"
              :maxTagCount="2"
              disabled
            >
              <a-select-option v-for="item in vehicleSeriesInfoList" :key="item.vehicleSeriesId">{{
                item.vehicleSeriesName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <template slot="footer">
      <a-row type="flex" justify="center">
        <a-col :span="3">
          <a-button type="primary" :loading="btnLoading" @click="handleLocalOk"> 确认上传 </a-button>
        </a-col>
        <a-col :span="3">
          <a-button @click="handleLocalCancel"> 退出 </a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import SparkMD5 from 'spark-md5';
import ossUploadMixin from '@/components/BaseUpload/ossUploadMixin.js';
import api from '@/api/xhsAgencyApi';
import CascaderMenu from '@/components/CascaderMenu.vue';

export default {
  props: {
    principalInfoList: {
      default: () => [],
    },
    vehicleSeriesInfoList: {
      default: () => [],
    },
  },
  components: { CascaderMenu },
  mixins: [ossUploadMixin],
  data() {
    return {
      show: true,
      btnLoading: false,
      file_list: [],
      showAddTag: true,
      addLoading: false,
      addTagText: '',
      tagList: [],
      brandList: [],
      seriesList: [],
      form: {
        brand: [],
        series: [],
      },
      tagTree: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 获取tag
      this.getTagList();
      // 已选品牌回显
      this.principalInfoList.forEach((val) => {
        this.form.brand.push(val.principalId);
      });
      // 车系回显
      this.vehicleSeriesInfoList.forEach((val) => {
        this.form.series.push(val.vehicleSeriesId);
      });
    },
    initTagValue(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTagValue);
      }
      return {
        ...data,
        name: data.tagName,
        value: data.tagPath,
        key: data.id,
      };
    },
    async getTagList() {
      await this.handleRequest('getTagTree', (data) => {
        this.tagTree = data.map(this.initTagValue);
      });
    },
    async handleLocalOk() {
      // 判断图片
      const fileInfoList = [];
      let hasLoading = false;
      this.file_list?.length &&
        this.file_list.forEach((v) => {
          if (v.status === 'uploading') {
            hasLoading = true;
          }
          if (v.status === 'done') {
            fileInfoList.push({
              fileId: v.fileId,
              fileUrl: v.url,
            });
          }
        });

      if (hasLoading) return this.$message.error('请等待全部图片上传完成');
      if (!fileInfoList?.length) return this.$message.error('请至少上传一张图片');

      // 处理标签字段
      let tagList = [];
      if (this.tagList?.length) {
        tagList = this.tagList.map((val) => ({
          tagId: val.id,
          tagName: val.text,
        }));
      } else {
        tagList = [];
      }
      if (tagList.length === 0) {
        this.$message.error('请至少选择一个标签');
        return;
      }

      const params = {
        fileInfoList,
        principalInfoList: this.principalInfoList,
        vehicleSeriesInfoList: this.vehicleSeriesInfoList,
        tagList,
      };
      this.btnLoading = true;
      await this.handleRequest(
        'addLibraryPic',
        () => {
          const picList = fileInfoList.map((val) => ({
            fileUrl: val.fileUrl,
            fileId: val.fileId,
          }));
          this.$emit('addPic', picList);
          this.handleLocalCancel();
        },
        params
      ).finally(() => (this.btnLoading = false));
    },
    handleLocalCancel() {
      this.show = false;
      this.$emit('closePicUpload');
    },
    // -------------------------------------------------------tags
    handleTagClick(id) {
      this.tagList.forEach((value) => {
        if (value.id === id) {
          value.isChoose = !value.isChoose;
        }
      });
    },
    async updateTagList() {
      await this.handleRequest('getTagList', (data) => {
        this.tagList.unshift({
          ...data.list[0],
          isChoose: false,
        });
      });
    },
    async handleTagOk() {
      if (this.addTagText) {
        this.addLoading = true;
        const params = {
          tagName: this.addTagText,
        };
        await this.handleRequest(
          'handleAddTag',
          () => {
            this.$message.success('添加成功');
            this.addTagText = undefined;
            this.showAddTag = true;
            this.updateTagList();
          },
          params
        ).finally(() => (this.addLoading = false));
      } else {
        this.showAddTag = true;
      }
    },

    handleTagChange(value, compIndex) {
      if (value.isAdd) {
        const treeData = this.tagTree[compIndex];
        const parentText = `${treeData.name} > ${value.parent.name} > `;
        const text = value.self.name;
        this.tagList.push({
          id: value.self.id,
          parentText,
          text,
          tagPath: value.self.tagPath,
          compIndex,
        });
      } else {
        const index = this.tagList.indexOf((ele) => ele.id === value.self.id);
        this.tagList.splice(index, 1);
      }
    },
    handleTagDel(value) {
      this.$refs.cascaderMenu[value.compIndex].delSelect(value.tagPath);
      let tagIndex = -1;
      this.tagList.forEach((ele, index) => {
        if (ele.id == value.id) {
          tagIndex = index;
        }
      });
      this.tagList.splice(tagIndex, 1);
    },

    // -------------------------------------------------------图片上传
    async customRequest(option) {
      const { file, onSuccess } = option;
      await this.normalUploadFile(file, onSuccess, true);
    },
    beforeUploadImg(file) {
      return new Promise((resolve, reject) => {
        let fileType = /\.(jpeg|jpg|png)$/i;
        if (!fileType.test(file.name)) {
          this.$message.error('仅支持图片文件');
          return false;
        }
        let fileReader = new FileReader();
        let spark = new SparkMD5.ArrayBuffer(); //获取文件二进制数据
        fileReader.readAsArrayBuffer(file); //file就是获取到的文件 //异步执行函数
        fileReader.onload = function (e) {
          spark.append(e.target.result);
          let md5 = spark.end();
          file.md5 = md5;
          file.params_data = {
            appId: 'bfz',
            filePath: '/bfz/xhs/',
            identifier: file.md5,
            name: file.name,
            title: file.name.split('.')[0],
          };
          return resolve(true);
        };
      });
    },
    handleChange(info) {
      if (info.file.status) {
        this.file_list = info.fileList;

        if (info.file.status === 'uploading') {
          this.file_list.forEach((val) => {
            if (val.fileId === info.file.fileId) {
              val.status = 'uploading';
            }
          });
        }

        if (info.file.status === 'done') {
          let fileList = [...info.fileList];
          fileList = fileList.map((file) => {
            if (file.response) {
              file.fileId = file.response.data.data.id;
              file.url = file.response.url;
              file.status = 'done';
            }
            return file;
          });
          this.file_list = fileList;
        }

        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 文件上传失败`);
        }
      } else {
        return;
      }
    },

    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.sub-title {
  margin-left: 8px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
}

.upload-btn {
  margin: 0 auto 10px;
  width: 97%;
  height: 45px;
  line-height: 45px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px #ebebeb dashed;
  text-align: center;
  cursor: pointer;
}

.btn-line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag-line {
  display: flex;

  .tag-title {
    padding-top: 5px;
    width: 50px;
    color: #262626;
  }

  .tag-body {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    color: #2d2d2d;
    align-items: center;

    .tags {
      padding: 5px 10px;
      margin: 0 10px 10px;
      border-radius: 6px;
      cursor: pointer;
    }

    .active {
      background-color: #1890ff;
      color: #fff;
    }

    .tag-add {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      .tag-input {
        width: 120px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .tag-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.bottom {
  display: flex;
  margin-top: 10px;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;

  .tags {
    margin: 0 5px 5px 0;
    padding: 0 5px;
    border: 1px solid #1890ff;
    border-radius: 3px;
    color: #80c2ff;
    user-select: none;
  }
}
</style>
