<template>
  <div class="pic-comp">
    <div class="top">
      <div>
        图片
        <a-button :disabled="picList.length === 18" type="link" @click="showPicStoreModal = !showPicStoreModal"
          >从成品库添加</a-button
        >
        |
        <a-button :disabled="picList.length === 18" type="link" @click="showPicUpload">本地上传</a-button>
      </div>
      <div style="color: #929292">{{ picList.length }}/18</div>
    </div>

    <div class="bottom">
      <div v-show="picList.length" id="container" ref="sortableBox" style="display: flex">
        <div
          class="bottom-item"
          v-for="value in picList"
          :key="value.id"
          @mouseenter="value.showHover = true"
          @mouseleave="value.showHover = false"
        >
          <div>
            <img class="bottom-item-img" :src="value.fileUrl" />
            <div class="hover-item" v-show="value.showHover">
              <div class="hover-item-top" @click="handleDel(value.id)">
                <a-icon type="close" style="color: #fff" />
              </div>
              <div class="hover-item-bottom" @click="handleReplace(value.id)">替换</div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!picList.length" class="tips">请至少添加一张图片</div>
    </div>

    <!-- 图库弹窗 -->
    <PicStoreModal
      v-if="showPicStoreModal"
      @closeStoreModal="closeStoreModal"
      @addPic="handleAddPic"
      :isReplace="isReplace"
      :principalInfoList="principalInfoList"
      :vehicleSeriesInfoList="vehicleSeriesInfoList"
    />

    <!-- 本地上传弹窗 -->
    <PicUploadModal
      v-if="showPicUploadModal"
      :principalInfoList="principalInfoList"
      :vehicleSeriesInfoList="vehicleSeriesInfoList"
      @addPic="handleAddPic"
      @closePicUpload="showPicUploadModal = false"
    />
  </div>
</template>

<script>
import PicStoreModal from './PicStoreModal.vue';
import PicUploadModal from './PicUploadModal';
import Sortable from 'sortablejs';
import { cloneDeep, debounce } from 'lodash';

export default {
  props: {
    picList: {
      default: () => [],
    },
    principalInfoList: {
      default: () => [],
    },
    vehicleSeriesInfoList: {
      default: () => [],
    },
  },
  components: {
    PicStoreModal,
    PicUploadModal,
  },
  data() {
    return {
      showPicStoreModal: false,
      isReplace: false,
      replaceId: '',
      showPicUploadModal: false,
    };
  },
  mounted() {},
  methods: {
    defineSortable() {
      this.$nextTick(() => {
        const el = this.$refs.sortableBox;
        const that = this;
        Sortable.create(el, {
          animation: 100, //拖拽动画过渡时间
          group: 'name',
          sort: true,
          onEnd: function (evt) {
            // 获取排序之后的data数据 同步至父组件
            const listData = cloneDeep(that.picList);
            listData.splice(evt.newIndex, 0, listData.splice(evt.oldIndex, 1)[0]);
            const newArray = listData.slice(0);
            that.$emit('updatePicList', newArray);
          },
        });
      });
    },
    handleHover(val) {
      val.showHover = true;
    },
    handleDel(id) {
      const list = JSON.parse(JSON.stringify(this.picList));
      const index = list.findIndex((val) => val.id === id);
      list.splice(index, 1);
      this.$emit('updatePicList', list);
    },
    handleAddPic(value) {
      let list = JSON.parse(JSON.stringify(this.picList));
      const newVal = value.map((val) => {
        return {
          id: Math.random(),
          showHover: false,
          fileUrl: val.fileUrl,
          fileId: val.fileId,
        };
      });
      if (this.isReplace) {
        // 替换逻辑
        const index = list.findIndex((value) => value.id === this.replaceId);
        list.splice(index, 1, ...newVal);
      } else {
        // 添加逻辑
        list.push(...newVal);
      }
      if (list.length > 18) {
        this.$message.info('最多只允许添加18张照片，超出部分未上传');
        list = list.splice(0, 18);
      }
      this.$emit('updatePicList', list);

      this.closeStoreModal();
    },
    closeStoreModal() {
      this.replaceId = '';
      this.isReplace = false;
      this.showPicStoreModal = false;
    },
    handleReplace(id) {
      this.replaceId = id;
      this.isReplace = true;
      this.showPicStoreModal = true;
    },
    showPicUpload() {
      this.replaceId = '';
      this.isReplace = false;
      this.showPicUploadModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.bottom {
  overflow-y: auto;
  height: 200px;

  .bottom-item {
    position: relative;
    margin: 0 0 10px 15px;

    .bottom-item-img {
      height: 180px;
      object-fit: cover;
    }

    .hover-item {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      max-height: 180px;
      border: 2px #cccccc solid;

      .hover-item-top {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 0 0 0 5px;
        background-color: #444d54;
        cursor: pointer;
      }

      .hover-item-bottom {
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        background-color: #33333345;
        cursor: pointer;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;

    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}

.tips {
  width: 100%;
  height: 200px;
  line-height: 200px;
  text-align: center;
  background-color: #fff;
  color: #33333340;
  letter-spacing: 2px;
}
</style>
