<template>
  <div class="story-comp">
    <div class="top">
      <div>故事</div>
      <a-button type="primary" :loading="btnLoading" @click="handleClick">仿写小红书风格</a-button>
    </div>
    <a-textarea :auto-size="{ minRows: 8, maxRows: 8 }" v-model="storyString" :disabled="btnLoading"></a-textarea>
    <div class="bottom">
      <span style="color: #999999; margin-right: 10px">{{ storyString.length || 0 }}个字</span>
    </div>
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  props: {
    storyText: {
      type: String,
      default: '',
    },
    storyRole: {
      type: String,
      default: '',
    },
    wordCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      btnLoading: false,
    };
  },
  computed: {
    storyString: {
      get() {
        return this.storyText;
      },
      set(val) {
        this.$emit('updateText', val);
      },
    },
  },
  methods: {
    async handleClick() {
      const params = {
        role: this.storyRole,
        story: this.storyString,
        wordCount: this.wordCount,
      };
      this.btnLoading = true;
      const { code, data, message } = await api.getNewContent(params).finally(() => (this.btnLoading = false));
      if (code === 200) {
        this.$emit('getNewNote', data);
      } else {
        this.$message.error(`${message}`);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.bottom {
  display: flex;
  justify-content: flex-end;
}
</style>
