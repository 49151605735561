<template>
  <div class="note-container">
    <div class="container-top">
      <div class="top">
        <div>笔记文案</div>
        <div class="btn-line">
          <a-button class="ml-5" :loading="btnLoading" @click="addEmoji">+表情</a-button>
          <a-button class="ml-5" :loading="btnLoading" @click="addTitle">+标题</a-button>
          <a-button class="ml-5" :loading="btnLoading" @click="addHashTag">+话题</a-button>
          <a-button class="ml-5" :loading="btnLoading" @click="automaticLineWrapping">自动分行</a-button>
          <!-- <a-button class="ml-5" :loading="btnLoading" type="primary" @click="handleSave">保存</a-button> -->
          <!-- <a-button class="ml-5" :loading="btnLoading" type="primary" @click="handlePublish">保存并发布</a-button> -->
        </div>
      </div>
      <div style="position: relative">
        <div>
          <a-textarea
            class="input-class"
            placeholder="笔记标题"
            :auto-size="{ maxRows: 3 }"
            v-model="noteTitle"
          ></a-textarea>
          <!-- <a-input placeholder="笔记标题" style="margin: 5px 0" v-model="noteTitle" allowClear></a-input> -->
        </div>
        <div style="margin-left: 10px; color: #999999" :class="['word-count', { red: noteTitle.length > 20 }]">
          {{ noteTitle.length || 0 }} / 20 <a-icon v-show="noteTitle.length > 20" type="exclamation" />
        </div>
      </div>
      <div id="wangeditor">
        <div ref="editorElem" class="editor-style"></div>
      </div>
      <div class="bottom">
        <div style="display: flex; color: #999999">
          <a-popover placement="topRight">
            <template slot="content">
              <p>小红书对特殊符号的计数较混乱，此处超过900字后，</p>
              <p>在小红书发布时需检测笔记是否完整。</p>
            </template>
            <div style="cursor: pointer"><a-icon type="question-circle" style="margin-right: 5px" />小红书计数规则</div>
          </a-popover>
          <span style="margin: 0 5px" :class="[{ red: wordCount > 900 }]">{{ wordCount || 0 }}/1000</span>
        </div>
      </div>
      <div class="tag-line">
        <div style="width: 50px">话题：</div>
        <div style="flex: 1">
          <!-- <a-tooltip v-if="tag.name.length > 3" :key="tag.name" :title="tag.name">
            <a-tag style="margin-bottom: 5px" color="blue" :key="tag.name" closable @close="() => handleClose(tag.id)">
              {{ `${tag.name.slice(0, 3)}...` }}
            </a-tag>
          </a-tooltip> -->
          <a-tag
            v-for="tag in tagList"
            :key="tag.id"
            style="margin-bottom: 5px"
            color="blue"
            closable
            @close="() => handleClose(tag.id)"
          >
            <span v-show="tag.isHot">🔥</span>{{ tag.name }}
          </a-tag>
          <a-tag v-if="!showHashTag" style="borderstyle: dashed" @click="handleAddTagClick">
            <a-icon type="plus" /> 话题</a-tag
          >
          <div style="display: flex; align-items: center" v-if="showHashTag">
            <a-select
              v-model="newHashTag"
              placeholder="请输入话题搜索"
              style="width: 380px"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              show-search
              @search="handleHashTagSearch"
              @change="handleHashTagChange"
              @blur="handleHashTagBlur"
            >
              <a-select-option v-for="value in searchTagList" :key="value.id" :value="value.id">
                <div style="display: flex; justify-content: space-between">
                  <div>{{ value.name }}</div>
                  <div style="color: #bfc3c9; font-size: 12px">{{ common.numEdit(value.viewNum) }}次浏览</div>
                </div>
              </a-select-option>
            </a-select>
            <a-icon type="loading" style="margin-left: 10px; color: #0000ff69" v-show="hashTagLoading" />
            <div style="margin-left: 10px" v-show="showTip">无匹配结果，请修改重试或前往小红书App新建该话题</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 操作 -->
    <!-- <div style="background-color: #f0f2f5">
      <a-space size="large">
        <a-button :loading="btnLoading" type="primary" @click="handlePublish">保存并发布</a-button>
        <a-button :loading="btnLoading" @click="handleSave">保存</a-button>
        <a-popconfirm
          placement="topRight"
          title="是否关闭当前页面？"
          ok-text="是"
          cancel-text="取消"
          @confirm="handleBackJump"
        >
          <a-button type="danger">关闭</a-button>
        </a-popconfirm>
      </a-space>
    </div> -->
  </div>
</template>

<script>
import { EMOJI_LIST } from '../constant';
import E from 'afantiEditor';
import api from '@/api/xhsAgencyApi';
import { cloneDeep, debounce } from 'lodash';

export default {
  data() {
    return {
      editor: null,
      noteString: '',
      noteContent: '',
      noteTitle: '',
      btnLoading: false,
      tagList: [],
      newHashTag: undefined,
      searchTagList: [],
      hashTagLoading: false,
      showHashTag: false,
      showTip: false,
      wordCount: 0,
    };
  },
  mounted() {
    this.initEditor();
  },
  methods: {
    addEmoji() {
      this.btnLoading = true;
      this.editor.disable();
      this.handleRequest(
        'getEmojiContent',
        (data) => {
          this.noteContent = data.note.replace(/\n/g, '<br />');
          this.editor.txt.html(this.noteContent);
        },
        {
          note: this.noteString,
        }
      ).finally(() => {
        this.btnLoading = false;
        this.editor.enable();
      });
    },
    addTitle() {
      this.btnLoading = true;
      this.editor.disable();
      this.handleRequest(
        'getContentTitle',
        (data) => {
          this.noteTitle = data.title;
        },
        {
          note: this.noteString,
        }
      ).finally(() => {
        this.btnLoading = false;
        this.editor.enable();
      });
    },
    addHashTag() {
      if (!this.noteTitle) return this.$message.error('请输入文案标题');
      if (!this.noteString) return this.$message.error('请输入文案内容');
      const params = {
        noteDetail: this.noteString,
        noteTitle: this.noteTitle,
      };
      this.btnLoading = true;
      this.editor.disable();
      this.handleRequest(
        'getHashTagContent',
        (data) => {
          const oldList = this.tagList.map((val) => val.id);
          const newList = data.filter((val) => oldList.indexOf(val.id) === -1);
          this.tagList.push(...newList);
        },
        params
      ).finally(() => {
        this.btnLoading = false;
        this.editor.enable();
      });
    },
    automaticLineWrapping() {
      this.btnLoading = true;
      this.editor.disable();
      this.handleRequest(
        'automaticLineWrapping',
        (data) => {
          this.noteContent = data.note.replace(/\n/g, '<br />');
          this.editor.txt.html(this.noteContent);
        },
        {
          note: this.noteString,
        }
      ).finally(() => {
        this.btnLoading = false;
        this.editor.enable();
      });
    },
    handleClose(id) {
      const list = cloneDeep(this.tagList);
      const tags = list.filter((tag) => tag.id !== id);
      this.tagList = tags;
    },
    getInnerText: function (t) {
      if (!t) return '';
      var e = null == t ? void 0 : t.innerHTML;
      e = e
        .replace(/<img([^>]+)data-set="x-x-/g, '')
        .replace(/-x-x([^>]*)>/g, '')
        .replace(/<br>/g, '<硬换行>')
        .replace(/<(\w+)([^>]*)class="data-clipboard-text-hide"([^>]*)>#<\/\1>/gi, '#')
        .replace(/<(\w+)(\s)class="content-hide([^>]*)>(.*?)<\/\1>/gi, '[话题]#')
        .replace(/<(\w+)([^>]*)class="data-clipboard-text-hide"([^>]*)>\[时刻\]#<\/\1>/gi, '[时刻]#');
      var n = document.createElement('div');
      (n.innerHTML = e), document.body.append(n);
      // var r = n.innerText.replace(/<硬换行>/g, '\t\n');
      var r = n.innerText.replace(/<硬换行>/g, '\n');
      return document.body.removeChild(n), r;
    },
    initEditor() {
      if (this.$refs.editorElem) this.editor = new E(this.$refs.editorElem);

      this.editor.config.focus = false;
      this.editor.config.placeholder = '';
      this.editor.config.zIndex = 1;
      this.editor.config.emotions = [];
      this.editor.config.showFullScreen = false;
      this.editor.config.menus = ['emoticon', 'undo', 'redo'];
      // 初始化自定义表情
      EMOJI_LIST.forEach((item) => {
        const { title, type, content } = item;
        this.editor.config.emotions.push({
          title,
          type,
          content: [...content],
        });
      });
      this.editor.create();
      this.editor.txt.html(this.noteContent);

      this.editor.config.onchange = (newHtml) => {
        this.noteContent = newHtml;
        this.noteString = this.getInnerText(this.$refs.editorElem);
        this.wordCount = this.editor.txt.text().replace(/&nbsp;/gi, ' ').length;
        // this.noteString = this.noteContent
        //   .replace(/<br\s*\/?>/gi, '\n')
        //   .replace(/<p\s*\/?>/gi, '\n')
        //   .replace(/<[^>]+>/gi, '')
        //   .replace(/&nbsp;/gi, ' ');
      };
    },
    handleAddTagClick() {
      this.showHashTag = true;
    },
    handleHashTagSearch: debounce(
      function (value) {
        if (value) {
          if (!this.noteTitle) return this.$message.error('请输入文案标题');
          if (!this.noteString) return this.$message.error('请输入文案内容');
          const params = {
            noteDetail: this.noteString,
            noteTitle: this.noteTitle,
            keyword: value,
          };
          this.searchTagList = [];
          this.showTip = false;
          this.hashTagLoading = true;
          this.handleRequest(
            'getHashTagSearch',
            (data) => {
              if (data.length) {
                this.searchTagList = data;
              } else {
                this.showTip = true;
              }
            },
            params
          ).finally(() => (this.hashTagLoading = false));
        }
      },
      500,
      true
    ),
    handleHashTagChange(value) {
      const item = this.searchTagList.find((val) => val.id === value);
      const index = this.tagList.findIndex((val) => val.id === value);
      if (index === -1) {
        this.tagList.push(item);
      } else {
        this.$message.info('该话题已存在');
      }
      this.newHashTag = undefined;
      this.showHashTag = false;
    },
    handleHashTagBlur() {
      this.showTip = false;
      this.showHashTag = false;
      this.newHashTag = undefined;
    },
    handleSave() {
      this.$emit('handleSave', {
        noteTitle: this.noteTitle,
        noteDetail: this.noteString,
        xhsTopicList: this.tagList,
      });
    },
    handlePublish() {
      if (!this.noteTitle || !this.noteString) return this.$message.error('标题或正文不能为空');
      this.$emit('handlePublish', {
        noteTitle: this.noteTitle,
        noteDetail: this.noteString,
      });
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    handleBackJump() {
      this.$router.replace({ name: 'copywritingList' });
    },
  },
};
</script>

<style lang="less" scoped>
.container-top {
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 6px;
  background-color: #fff;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.red {
  color: red !important;
}

.editor-style {
  /deep/ .w-e-text-container {
    min-height: 650px;
    width: 100%;
  }
}

.tag-line {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  color: #ababab;
}

.input-class /deep/ .ant-input:not(:last-child) {
  padding-right: 100px !important;
}

.word-count {
  position: absolute;
  top: 5px;
  right: 30px;
  margin-left: 10px;
  color: #999999;
  z-index: 1;
}
</style>
